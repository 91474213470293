import type { RouteRecordRaw } from 'vue-router';
import store from '@/store';
import project from '@/modules/shared/store/project';
import { collectionList } from '@/modules/collectionList';
import teamFeatures from '@/modules/shared/store/teamFeatures';
import { projectVideoList } from '@/modules/projectVideoList';
import { collectionTypeList } from '@/modules/collectionType';
import { customImageFieldList } from '@/modules/customImageField';
import collection from '../store';

export function createCollectionRoutes(): RouteRecordRaw[] {
  return [
    {
      path: 'projects/:projectKey/collections/:contentCollectionId',
      redirect: { name: 'editCollectionDetails' },
    },
    {
      path: 'projects/:projectKey/collections/:contentCollectionId/edit',
      component: () => import(/* webpackChunkName: "CollectionChrome" */ '../layout/CollectionChrome.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.hasModule('collection')) {
          store.registerModule('collection', collection);
        }
        if (!store.hasModule('collectionList')) {
          store.registerModule('collectionList', collectionList);
        }
        if (!store.hasModule('collectionTypeList')) {
          store.registerModule('collectionTypeList', collectionTypeList);
        }
        if (!store.hasModule('project')) {
          store.registerModule('project', project);
        }
        // For adding videos to a collection.
        if (!store.hasModule('projectVideoList')) {
          store.registerModule('projectVideoList', projectVideoList);
        }
        if (!store.hasModule('teamFeatures')) {
          store.registerModule('teamFeatures', teamFeatures);
        }
        if (!store.hasModule('customImageFieldList')) {
          store.registerModule('customImageFieldList', customImageFieldList);
        }
        next();
      },
      children: [
        {
          name: 'editCollectionDetails',
          path: '',
          component: () => import(/* webpackChunkName: "EditCollectionDetails" */ '../pages/EditCollectionDetails.vue'),
        },
        {
          name: 'editCollectionAppearance',
          path: 'appearance',
          component: () => import(/* webpackChunkName: "EditCollectionAppearance" */ '../pages/EditCollectionAppearance.vue'),
        },
        {
          name: 'editCollectionVideos',
          path: 'videos',
          component: () => import(/* webpackChunkName: "EditCollectionVideos" */ '../pages/EditCollectionVideos.vue'),
        },
        {
          name: 'editCollectionVisibility',
          path: 'visibility',
          component: () => import(/* webpackChunkName: "EditCollectionVisibility" */ '../pages/EditCollectionVisibility.vue'),
        },
        {
          name: 'editCollectionBumper',
          path: 'bumper',
          component: () => import(/* webpackChunkName: "EditCollectionBumperDisplay" */ '../pages/EditCollectionBumperDisplay.vue'),
        },
      ],
    },
  ];
}
