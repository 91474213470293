import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pagination"
}
const _hoisted_2 = { class: "button-text" }
const _hoisted_3 = { class: "pagination-group" }
const _hoisted_4 = { class: "button-text" }
const _hoisted_5 = { class: "button-text" }

import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

interface CursorPaginationProps {
  nextPageToken: string | undefined;
  prevPageToken: string | undefined;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CursorPagination',
  props: {
    nextPageToken: {},
    prevPageToken: {}
  },
  setup(__props: any) {

const props = __props;
const { nextPageToken, prevPageToken } = toRefs(props);

const route = useRoute();
const { t } = useI18n();

const isShowPagination = computed(() => (
  typeof nextPageToken.value === 'string' || typeof prevPageToken.value === 'string'
));
const path = computed(() => route.path);
const query = computed(() => route.query);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (isShowPagination.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          title: _unref(t)('common:pagination.first'),
          "aria-label": _unref(t)('common:pagination.first'),
          "aria-disabled": !_unref(prevPageToken),
          class: "page-link page-previous link",
          to: { path: path.value, query: { ...query.value, previous: undefined, next: undefined } }
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-angles-left" }, null, -1)),
            _cache[1] || (_cache[1] = _createTextVNode()),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(t)('common:pagination.first_short')), 1)
          ]),
          _: 1
        }, 8, ["title", "aria-label", "aria-disabled", "to"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            title: _unref(t)('common:pagination.prev'),
            "aria-label": _unref(t)('common:pagination.prev'),
            "aria-disabled": !_unref(prevPageToken),
            class: "page-link page-previous link",
            to: { path: path.value, query: { ...query.value, previous: _unref(prevPageToken), next: undefined } }
          }, {
            default: _withCtx(() => [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fas fa-angle-left" }, null, -1)),
              _cache[3] || (_cache[3] = _createTextVNode()),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('common:pagination.prev_short')), 1)
            ]),
            _: 1
          }, 8, ["title", "aria-label", "aria-disabled", "to"]),
          _createVNode(_component_router_link, {
            title: _unref(t)('common:pagination.next'),
            "aria-label": _unref(t)('common:pagination.next'),
            "aria-disabled": !_unref(nextPageToken),
            class: "page-link page-next link",
            to: { path: path.value, query: { ...query.value, next: _unref(nextPageToken), previous: undefined } }
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)('common:pagination.next_short')), 1),
              _cache[4] || (_cache[4] = _createTextVNode()),
              _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-angle-right" }, null, -1))
            ]),
            _: 1
          }, 8, ["title", "aria-label", "aria-disabled", "to"])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})