<template>
  <div>
    <UrlSigningKeyInformationModal
      :urlSigningKey="urlSigningKey"
      :description="computedDescription"
      :onCloseModal="onCloseURLSigningKeyInformationModal"
      v-if="isURLSigningKeyInformationModalShowing"
    />
    <ConfirmDeleteUrlSigningKeyModal
      :deleteUrlSigningKey="deleteUrlSigningKey"
      :onCloseModal="onCloseDeleteUrlSigningKey"
      :onConfirmDeleted="load"
      v-if="deleteUrlSigningKey"
    />
    <div class="list-wrapper">
      <ErrorMessage
        v-if="urlSigningKeyList && urlSigningKeyList.length === 0"
        icon="fas fa-box-open"
        title="URL Signing Key was not found.">
        <Button
          :url="`/${teamName}/manage/url-signing-keys/create`"
          buttonStyle="primary"
        >
          <template v-slot:icon-prefix>
            <i class="fas fa-plus mr-1"></i>
          </template>
          {{ $t('urlSigningKey.create.title') }}
        </Button>
      </ErrorMessage>
      <DataTable
        responsiveLayout="c"
        :onSearchData="onSearch"
        :searchInputValue="searchValue"
        :searchInputPlaceholder="$t('common:dataTable.searchBy', { item: 'Key ID' })"
        v-else>
        <template v-slot:table-action v-if="isManagerOrAdmin">
          <Button
            :url="`/${teamName}/manage/url-signing-keys/create`"
            buttonStyle="secondary"
          >
            <template v-slot:icon-prefix>
              <i class="fas fa-plus mr-1"></i>
            </template>
            {{ $t('urlSigningKey.create.title') }}
          </Button>
        </template>
        <template v-slot:table-header>
          <Row>
            <Column>
              <HeaderCell text="KEY ID" />
            </Column>
            <Column/>
            <Column width="80px" v-if="isManagerOrAdmin"/>
          </Row>
        </template>
        <template v-slot:table-content>
          <Row
            v-for="urlSigningKey in filteredUrlSigningKeyList"
            :key="urlSigningKey.id"
            @click="onToggleURLSigningKeyInformationModal(urlSigningKey)"
          >
            <Column>
              <TextCell :primaryText="urlSigningKey.keyId"/>
            </Column>
            <Column>
              <Button
                buttonStyle="link-primary"
                @click="onToggleURLSigningKeyInformationModal(urlSigningKey)"
              >
                Show key secret
              </Button>
            </Column>
            <Column width="80px" horizontalAlign="right" isActionColumn v-if="isManagerOrAdmin">
              <Button
                buttonStyle="text-secondary"
                @click.stop="onToggleDeleteUrlSigningKey(urlSigningKey)"
                isSquare
              >
                <i class="fas fa-trash-can"></i>
              </Button>
            </Column>
            <template v-slot:responsive-c-button>
              <Button
                buttonStyle="text-secondary"
                @click.stop="onToggleDeleteUrlSigningKey(urlSigningKey)"
              >
                <i class="fas fa-trash-can"></i>
              </Button>
            </template>
          </Row>
          <MessageBox
            v-if="isEmptyUrlSigningKeyList"
            :title="$t('common:search.resultEmpty.title', { q: 'URL Signing Key' })"
          />
          <EmptySearch
            v-if="filteredUrlSigningKeyList?.length === 0"
            :keyword="searchValue"
            :onClearSearch="onClearSearch" />
        </template>
      </DataTable>
      <div v-if="lastPage > 1" class="pagination-container">
        <Pagination :lastPage="lastPage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { formatVideoDateTime } from '@/modules/shared/utils/dateFormatter';
import { goToPageDetail } from '@/modules/shared/utils/routerHistory';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import TextCell from '@/modules/shared/components/molecules/textCell/TextCell.vue';
import ErrorMessage from '@/modules/shared/components/atoms/errorMessage/ErrorMessage.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import Pagination from '@/modules/shared/components/molecules/pagination/Pagination.vue';
import ConfirmDeleteUrlSigningKeyModal from '../confirmDeleteUrlSigningKeyModal/ConfirmDeleteUrlSigningKeyModal.vue';
import UrlSigningKeyInformationModal from '../urlSigningKeyInformationModal/UrlSigningKeyInformationModal.vue';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    UrlSigningKeyInformationModal,
    ConfirmDeleteUrlSigningKeyModal,
    Button,
    DataTable,
    Column,
    Row,
    HeaderCell,
    TextCell,
    ErrorMessage,
    MessageBox,
    EmptySearch,
    Pagination,
  },
  computed: {
    ...mapState({
      lastPage: (state) => state.urlSigningKeyList.lastPage,
      urlSigningKeyList: (state) => state.urlSigningKeyList.urlSigningKeyList,
      loadUrlSigningKeyListState: (state) => state.urlSigningKeyList.loadUrlSigningKeyListState,
    }),
    ...mapGetters(['isEmptyUrlSigningKeyList', 'isManagerOrAdmin']),
    filter() {
      return {
        ...this.$route.query,
        limit: 100,
      };
    },
    filteredUrlSigningKeyList() {
      if (this.searchValue) {
        return this.urlSigningKeyList.filter((key) => key.keyId.includes(this.searchValue));
      }
      return this.urlSigningKeyList;
    },
    teamName() {
      return this.$route.params.teamName;
    },
    computedDescription() {
      return `${this.$t('urlSigningKey.information.useKey')} ${this.$t(
        'urlSigningKey.information.requireSignedURL',
      )} ${this.$t('urlSigningKey.information.seeDocument')}`;
    },
  },
  data() {
    return {
      isURLSigningKeyInformationModalShowing: false,
      deleteUrlSigningKey: null,
      searchValue: '',
    };
  },
  watch: {
    filter: {
      handler(newValue) {
        this.load(newValue);
      },
      deep: true,
    },
  },
  methods: {
    formatVideoDateTime,
    goToPageDetail,
    async load(filter) {
      await this.$store.dispatch('loadUrlSigningKeys', filter);
    },
    onSearch(event) {
      this.searchValue = event.target.value;
    },
    onToggleDeleteUrlSigningKey(deleteUrlSigningKey) {
      this.deleteUrlSigningKey = deleteUrlSigningKey;
    },
    onCloseDeleteUrlSigningKey() {
      this.deleteUrlSigningKey = null;
    },
    onToggleURLSigningKeyInformationModal(urlSigningKey) {
      this.urlSigningKey = urlSigningKey;
      this.isURLSigningKeyInformationModalShowing = true;
    },
    onCloseURLSigningKeyInformationModal() {
      this.urlSigningKey = null;
      this.isURLSigningKeyInformationModalShowing = false;
    },
    onClearSearch() {
      this.searchValue = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/breakpoint.scss';
@import '~@/assets/scss/global-variables.scss';

:deep(.action-button) {
  margin-top: $spacing-base * 1.5;
}

.search {
  width: 400px;
}

.btn-delete {
  z-index: 1;
}

.url-signing-key-action {
  width: auto !important;
  display: flex !important;
}

:deep(.add-url-signing-key-button) {
  margin-top: $spacing-24;
}

.list-wrapper {
  /* background-color: #FBFBFC; */
  // border: 1px solid $grey-300;
  /* border-radius: 4px; */

  .title {
    color: $grey-800;
  }

  :deep(.btn-action) {
    &:first-child {
      margin-right: $spacing-base * 0.5;
    }
  }
}

.pagination-container {
  margin-top: $spacing-base;
}

@media screen and (max-width: $max-layout-sm) {
  .search {
    width: 100%;
  }
}
</style>
