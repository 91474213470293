import { axiosAPIv2 } from '@/plugins/axios';
import type {
  LighthouseCustomDimension,
  UpdateVideoLighthouseDimensionsPayload,
} from '../types';

export const getVideoLighthouseDimensions = () => axiosAPIv2.get<LighthouseCustomDimension>('video-dimensions');

export const updateVideoLighthouseDimensions = (payload: UpdateVideoLighthouseDimensionsPayload) => (
  axiosAPIv2.put<LighthouseCustomDimension>('video-dimensions', payload)
);
