<template>
  <div>
    <SuccessToast
      v-if="areProjectVideosDeleted"
      :duration="10000"
      :title="t('project.deleteVideos.success.title')"
      :description="t('project.deleteVideos.success.description')"
    />
    <Alert
      v-if="!isLoading && !isEmptyRecentlyDeleted"
      :alertText="t('recentlyDeleted.info')"
      priority="info"
    />
    <PreLoaderSection
      v-if="isLoading" />
    <Section
      v-if="!isLoading && !isEmptyRecentlyDeleted">
      <DataTable>
        <template v-slot:table-header>
          <Row>
            <Column width="140px"><HeaderCell /></Column>
            <Column minWidth="200px" isPrimaryColumn><HeaderCell :text="t('videoList.table.videoInfo.title')" /></Column>
            <Column width="200px"><HeaderCell :text="t('project.deleteVideos.table.fromProject')" /></Column>
            <Column width="200px"><HeaderCell :text="t('common:deletedAt')" /></Column>
            <Column width="140px" isActionColumn><HeaderCell /></Column>
          </Row>
        </template>
        <template v-slot:table-content>
          <DeletedVideoItem
            v-for="(video, itemIndex) in videoList"
            :key="itemIndex"
            :video="getVideoFromVideosUpload(video)"/>
        </template>
      </DataTable>
      <template v-slot:section-footer>
        <div class="pagination-container" v-if="isShowPagination()">
          <Pagination
            :lastPage="lastPage" />
        </div>
      </template>
    </Section>
    <Section
      v-if="!isLoading && !error && isEmptyRecentlyDeleted"
      class="section-recently-deleted">
      <MessageBox
        iconClassName="fas fa-trash"
        :title="t('recentlyDeleted.empty.title')"
        :description="t('recentlyDeleted.empty.description')" />
    </Section>
    <Section v-if="!isLoading && error">
      <MessageBox
        iconClassName="fas fa-circle-exclamation"
        :title="t('response:loadingFailed.title')" />
    </Section>
  </div>
</template>

<script lang="ts" setup>
import SuccessToast from '@/modules/shared/components/molecules/toasts/SuccessToast.vue';
import _ from 'lodash';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { getQueryAsString } from '@/modules/shared/utils/query';
import Section from '@/modules/shared/components/organisms/section/Section.vue';
import Pagination from '@/modules/shared/components/molecules/pagination/Pagination.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Alert from '@/modules/shared/components/atoms/alert/Alert.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import Column from '@/modules/shared/components/molecules/column/Column.vue';
import HeaderCell from '@/modules/shared/components/molecules/headerCell/HeaderCell.vue';
import Row from '@/modules/shared/components/molecules/row/Row.vue';
import DataTable from '@/modules/shared/components/organisms/dataTable/DataTable.vue';
import DeletedVideoItem from '../components/molecules/deletedVideoItem/DeletedVideoItem.vue';
import { useStore } from '../store/hooks';

const { t } = useI18n();
const store = useStore();
const route = useRoute();

const error = ref<unknown>(null);
const videoList = computed(() => store.state.projectDeletedVideoList.projectDeletedVideoList);
const isLoading = computed(() => store.state.projectDeletedVideoList.loadProjectDeletedVideoListState.status === 'loading');
const lastPage = computed(() => store.state.projectDeletedVideoList.projectDeletedVideoListLastPage); // ref<number>(1);

const getVideoFromVideosUpload = computed(() => store.getters.getVideoFromVideosUpload);
const areProjectVideosDeleted = computed(() => store.state.global.areProjectVideosDeleted);

const query = computed(() => route.query);
const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));

const isEmptyRecentlyDeleted = computed(() => !isLoading.value && _.isEmpty(videoList.value));

async function load() {
  await store.dispatch('loadProjectDeletedVideoList', { filter: { ...query.value, projectKey: projectKey.value } });
}

function isShowPagination() {
  return lastPage.value > 1;
}

watch(query, () => {
  if (route.name === 'recentlyDeleted') {
    load();
  }
}, { deep: true });

onMounted(load);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

.section-recently-deleted {
  padding-top: $spacing-base;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
