<template>
  <div v-if="isShowPagination" class="pagination">
    <router-link
      :title="t('common:pagination.first')"
      :aria-label="t('common:pagination.first')"
      :aria-disabled="!prevPageToken"
      class="page-link page-previous link"
      :to="{ path, query: { ...query, previous: undefined, next: undefined } }"
    >
      <i class="fas fa-angles-left"></i> <span class="button-text">{{t('common:pagination.first_short')}}</span>
    </router-link>
    <div class="pagination-group">
      <router-link
        :title="t('common:pagination.prev')"
        :aria-label="t('common:pagination.prev')"
        :aria-disabled="!prevPageToken"
        class="page-link page-previous link"
        :to="{ path, query: { ...query, previous: prevPageToken, next: undefined } }"
      >
        <i class="fas fa-angle-left"></i> <span class="button-text">{{t('common:pagination.prev_short')}}</span>
      </router-link>
      <router-link
        :title="t('common:pagination.next')"
        :aria-label="t('common:pagination.next')"
        :aria-disabled="!nextPageToken"
        class="page-link page-next link"
        :to="{ path, query: { ...query, next: nextPageToken, previous: undefined } }"
      >
        <span class="button-text">{{t('common:pagination.next_short')}}</span> <i class="fas fa-angle-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

interface CursorPaginationProps {
  nextPageToken: string | undefined;
  prevPageToken: string | undefined;
}

const props = defineProps<CursorPaginationProps>();
const { nextPageToken, prevPageToken } = toRefs(props);

const route = useRoute();
const { t } = useI18n();

const isShowPagination = computed(() => (
  typeof nextPageToken.value === 'string' || typeof prevPageToken.value === 'string'
));
const path = computed(() => route.path);
const query = computed(() => route.query);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.pagination {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.pagination-group {
  display: flex;
  gap: 16px;
  width: fit-content;
}

.page-link {
  border: 1px solid $grey-200;
  border-radius: $border-radius-5;
  background: $grey-200;
  color: $grey-600;
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 126px;
  height: 36px;
  transition: all 150ms;

  .fa-angles-left, .fa-angle-left {
    margin-right: $spacing-4;
  }

  .fa-angle-right {
    margin-left: $spacing-4;
  }

  &:hover {
    background: #f1f2f3;
  }

  &[aria-disabled="true"] {
    pointer-events: none;
    opacity: 0.5;
  }
}

@media screen and (max-width: $max-layout-sm) {
  .page-link {
    min-width: unset;

    .fa-angles-left, .fa-angle-left, .fa-angle-right {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .button-text {
    display: none;
  }
}
</style>
