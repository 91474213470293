<template>
  <MessageBox
    iconClassName="fas fa-search"
    :title="keyword ? t('common:search.resultEmpty.title', { q: keyword }) : t('common:search.resultEmpty.title_noSearchKeyword')"
    :description="keyword ? t('common:search.resultEmpty.description') : t('common:search.resultEmpty.description_noSearchKeyword')"
    :buttonTitle="isShowOnClearButton ? t('common:search.resultEmpty.buttonTitle') : ''"
    :onClickButton="onClickClearSearch"
    :dataTest="dataTest"
  />
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import type { Nullable } from '@/modules/shared/types/index.type';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';

interface EmptySearchProps {
  keyword?: Nullable<string>;
  onClearSearch?: () => (void | Promise<void>);
  dataTest?: Nullable<string>;
  isShowOnClearButton?: boolean;
}

const props = withDefaults(defineProps<EmptySearchProps>(), {
  isShowOnClearButton: true,
  dataTest: 'empty-search-container',
});

const {
  keyword,
  onClearSearch,
  dataTest,
} = toRefs(props);

const { t } = useI18n();
const router = useRouter();

const onClickClearSearch = () => {
  router.push({ query: undefined });

  if (onClearSearch.value) {
    onClearSearch.value();
  }
};
</script>
