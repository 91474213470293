import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "filter-title" }

import type { AdvancedDatePickerDateType } from '@/modules/shared/types/input.type';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { getQueryAsString, type AdvancedDatePickerValue, type VideoTag } from '@/modules/shared';
import { loadVideoTags } from '@/modules/videoDetail/services';
import {
  AdvancedDatePicker, Button, SlideOut, FormMultiSelect,
} from '@/modules/shared';
import { useStore } from '@/modules/projectVideoList/store/hooks';

interface Props {
  useDateFilter?: boolean;
  onClose: () => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterSlideOut',
  props: {
    useDateFilter: { type: Boolean },
    onClose: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const { useDateFilter, onClose } = toRefs(props);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

const now = new Date();

const options = ref<VideoTag[]>([]);
const selectedTags = ref<VideoTag[]>([]);
const filteredDateType = ref<AdvancedDatePickerDateType>('dateRange');
const filteredDate = ref<AdvancedDatePickerValue>(null);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const currentProject = computed(() => store.state.project.currentProject);

async function loadTags(q?: string) {
  if (!projectKey.value) {
    return;
  }

  try {
    const response = await loadVideoTags({ projectKey: projectKey.value, q });
    options.value = response.data?.data;
  } catch (error) {
    console.error(error);
  }
}

function onSearchTag(query: { q: string }) {
  loadTags(query.q);
}

function pushQueryParam() {
  const tagIds = selectedTags.value?.map((tag) => tag.id);
  router.push({ query: { tagIds: tagIds.join(',') } });
}

function onChangeTags(tags: VideoTag[]) {
  selectedTags.value = tags;
  pushQueryParam();
}

function getSelectedTags() {
  let { tagIds } = route.query;

  if (typeof tagIds === 'string' && tagIds.length > 0) {
    tagIds = tagIds.split(',');

    const selected: VideoTag[] = [];

    // preserve user selected order
    tagIds.forEach((id) => {
      const tag = options.value?.find((item) => item.id === id);
      if (tag) {
        selected?.push(tag);
      }
    });

    selectedTags.value = selected;
  }
}

function clearSearch() {
  router.push({ query: {} });
  selectedTags.value = [];
  onClose.value();
}

watch(filteredDate, (date) => {
  const prevQuery = {
    ...route.query,
    next: undefined,
    previous: undefined,
  };

  if (!date) {
    router.push({
      query: {
        ...prevQuery,
        dateFilterType: undefined,
        startDate: undefined,
        endDate: undefined,
        month: undefined,
        year: undefined,
      },
    });
    return;
  }

  if (typeof date === 'number') {
    router.push({
      query: {
        ...prevQuery,
        dateFilterType: 'year',
        startDate: undefined,
        endDate: undefined,
        month: undefined,
        year: date,
      },
    });
    return;
  }

  if (typeof date === 'object' && 'month' in date && 'year' in date) {
    router.push({
      query: {
        ...prevQuery,
        dateFilterType: 'month',
        startDate: undefined,
        endDate: undefined,
        month: date.month,
        year: date.year,
      },
    });
    return;
  }

  router.push({
    query: {
      ...prevQuery,
      dateFilterType: 'dateRange',
      startDate: date[0].toISOString().substring(0, 10),
      endDate: date[1].toISOString().substring(0, 10),
      month: undefined,
      year: undefined,
    },
  });
});

function setDefaultFilteredDateFromQuery(): void {
  const dateObject = {
    dateFilterType: route.query.dateFilterType,
    startDate: route.query.startDate,
    endDate: route.query.endDate,
    month: route.query.month,
    year: route.query.year,
  };

  const {
    dateFilterType, startDate, endDate, month, year,
  } = dateObject;

  if (dateFilterType === 'year' && typeof year === 'string') {
    filteredDateType.value = dateFilterType;
    filteredDate.value = parseInt(year, 10);
    return;
  }

  if (dateFilterType === 'month' && typeof year === 'string' && typeof month === 'string') {
    filteredDateType.value = dateFilterType;
    filteredDate.value = {
      month: parseInt(month, 10),
      year: parseInt(year, 10),
    };
    return;
  }

  if (dateFilterType === 'dateRange' && typeof startDate === 'string' && typeof endDate === 'string') {
    filteredDateType.value = dateFilterType;
    filteredDate.value = [new Date(startDate), new Date(endDate)];
  }
}

onMounted(async () => {
  await loadTags();
  getSelectedTags();
  setDefaultFilteredDateFromQuery();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(SlideOut), {
    title: _unref(t)('common:filters'),
    titleIconClassName: "fa fa-filter",
    isShowModalFooter: false,
    onSlideOutClose: _unref(onClose),
    dataTest: "filter-slide-out"
  }, {
    default: _withCtx(() => [
      (_unref(useDateFilter))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('glossary:date')), 1),
            _createVNode(_unref(AdvancedDatePicker), {
              modelValue: filteredDate.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filteredDate).value = $event)),
              minDate: currentProject.value ? new Date(currentProject.value.createdAt) : undefined,
              maxDate: _unref(now),
              dateType: filteredDateType.value
            }, null, 8, ["modelValue", "minDate", "maxDate", "dateType"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_unref(FormMultiSelect), {
        title: _unref(t)('common:tags'),
        modelValue: selectedTags.value,
        options: options.value,
        searchable: true,
        taggable: true,
        onOnSearch: onSearchTag,
        onOnChanged: onChangeTags
      }, null, 8, ["title", "modelValue", "options"]),
      _createVNode(_unref(Button), {
        buttonStyle: "text-primary",
        buttonClasses: "clear-search-button",
        onClick: clearSearch
      }, {
        content: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(t)('common:tag.resultEmpty.buttonTitle')), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "onSlideOutClose"]))
}
}

})