import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, vModelText as _vModelText, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "date-input-wrapper" }
const _hoisted_3 = { class: "advanced-date-picker-header-container" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = { class: "target-date-container" }
const _hoisted_6 = {
  key: 0,
  class: "input-invalid-feedback"
}
const _hoisted_7 = { class: "tab-buttons-container" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 0,
  class: "day-view-current-month-container fade-in"
}
const _hoisted_10 = { class: "month-text" }
const _hoisted_11 = { class: "change-month-buttons-container" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "year-text" }
const _hoisted_15 = ["disabled", "onClick"]
const _hoisted_16 = ["onClick"]

import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import Datepicker, { type VueDatePickerProps } from '@vuepic/vue-datepicker';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import type { AdvancedDatePickerValue, AdvancedDatePickerDateType } from '@/modules/shared/types/input.type';
import '@vuepic/vue-datepicker/dist/main.css';

interface AdvancedDatePickerProps {
  disabled?: boolean;
  modelValue: AdvancedDatePickerValue;
  minDate?: Date;
  maxDate?: Date;
  dateType?: 'dateRange' | 'month' | 'year';
}

interface Tab {
  displayName: string;
  key: AdvancedDatePickerDateType;
}

const DEFAULT_MIN_YEAR: Readonly<number> = 2015;

const dayRegex = '([0-2]\\d|3[0-1]|[1-9])';
const monthRegex = '(0?[1-9]|[1][0-2])';
const yearRegex = '(2\\d{2}[1-9]|2\\d[1-9]0|[3-9]\\d{3}|\\d{2,}\\d{3})';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvancedDatePicker',
  props: {
    disabled: { type: Boolean },
    modelValue: {},
    minDate: {},
    maxDate: {},
    dateType: { default: 'dateRange' }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const {
  disabled,
  modelValue,
  minDate,
  maxDate,
  dateType,
} = toRefs(props);

const { t } = useI18n();

const emit = __emit;

const formatMap: Record<AdvancedDatePickerDateType, string> = {
  dateRange: 'dd/MM/yyyy',
  month: 'MM/yyyy',
  year: 'yyyy',
};

const currentTab = ref<AdvancedDatePickerDateType>(dateType.value);
const displayFormat = computed(() => formatMap[currentTab.value]);

const localValue = ref<AdvancedDatePickerValue>(modelValue.value);
const inputValue = ref<string>('');
const datePickerRef = ref<VueDatePickerProps | null>(null);

const now = ref<Date>(new Date());
const years = ref<number[]>([]);
const monthViewContainer = ref<HTMLDivElement | null>(null);
const yearViewContainer = ref<HTMLDivElement | null>(null);
const isSelectedDateOutOfRange = ref<boolean>(false);
const isInputting = ref<boolean>(false);

const tabs: Tab[] = [
  { displayName: 'Day', key: 'dateRange' },
  { displayName: 'Month', key: 'month' },
  { displayName: 'Year', key: 'year' },
];

function getDisplayMonthYear(month: number | undefined, year: number): string {
  if (typeof month === 'number') {
    return new Date(year, month, 1).toLocaleString('default', { month: 'long', year: 'numeric' });
  }

  return '';
}

function getLastSelectedDate(): Date | null {
  if (!localValue.value) {
    return now.value;
  }

  if (Array.isArray(localValue.value)) {
    return new Date(localValue.value[0]);
  }

  if (typeof localValue.value === 'object' && 'month' in localValue.value && 'year' in localValue.value) {
    return new Date(localValue.value.year, localValue.value.month, now.value.getDate());
  }

  return new Date(localValue.value, now.value.getMonth(), now.value.getDate());
}

function updateInputValueByTabKey(tabKey: AdvancedDatePickerDateType) {
  if (!localValue.value) {
    inputValue.value = '';
    return;
  }

  const dateFormat = 'dd/MM/yyyy';

  if (tabKey === 'dateRange' && Array.isArray(localValue.value)) {
    inputValue.value = `${format(localValue.value[0], dateFormat)}${localValue.value[1] ? ` - ${format(localValue.value[1], dateFormat)}` : ''}`;
  } else if (tabKey === 'month' && typeof localValue.value === 'object' && 'month' in localValue.value && 'year' in localValue.value) {
    const displayMonth = localValue.value.month + 1;
    inputValue.value = `${displayMonth < 9 ? `0${displayMonth}` : displayMonth}/${localValue.value.year}`;
  } else {
    inputValue.value = `${localValue.value}`;
  }
}

function checkIfMonthYearOutOfRange(month: number, year: number): boolean {
  const isExceedNow = now.value.getFullYear() === year && month > now.value.getMonth();
  let isBelowMinDate = false;
  let isExceedMaxDate = false;

  if (minDate.value) {
    const minMonth = minDate.value.getMonth();
    const minYear = minDate.value.getFullYear();

    isBelowMinDate = year < minYear || (year === minYear && month < minMonth);
  }

  if (maxDate.value) {
    const maxMonth = maxDate.value.getMonth();
    const maxYear = maxDate.value.getFullYear();

    isExceedMaxDate = year > maxYear || (year === maxYear && month > maxMonth);
  }

  return isExceedNow || isBelowMinDate || isExceedMaxDate;
}

function checkIfFullDateOutOfRange(date: Date): boolean {
  const baseDateWithoutTime = date.setHours(0, 0, 0, 0);
  const minDateWithoutTime = minDate.value ? minDate.value.setHours(0, 0, 0, 0) : baseDateWithoutTime;
  const maxDateWithoutTime = maxDate.value ? maxDate.value.setHours(0, 0, 0, 0) : baseDateWithoutTime;

  /** แปล: minDate มากกว่าวันที่เลือก || วันที่เลือกมากกว่า maxDate */
  if ((isAfter(minDateWithoutTime, baseDateWithoutTime)) || (isAfter(baseDateWithoutTime, maxDateWithoutTime))) {
    return true;
  }

  return false;
}

function onClickTab(tabKey: AdvancedDatePickerDateType): void {
  if (currentTab.value === tabKey) {
    return;
  }

  currentTab.value = tabKey;
  isSelectedDateOutOfRange.value = false;

  const lastSelectedDate = getLastSelectedDate();

  const baseDate = lastSelectedDate ?? now.value;

  if (tabKey === 'dateRange') {
    localValue.value = [baseDate, baseDate];
    isSelectedDateOutOfRange.value = checkIfFullDateOutOfRange(baseDate);
  } else if (tabKey === 'month') {
    localValue.value = {
      month: baseDate.getMonth(),
      year: baseDate.getFullYear(),
    };

    isSelectedDateOutOfRange.value = checkIfMonthYearOutOfRange(baseDate.getMonth(), baseDate.getFullYear());

    setTimeout(() => {
      if (monthViewContainer.value) {
        monthViewContainer.value!.scrollTo({ left: 0, top: 99999 });
      }
    }, 50);
  } else {
    localValue.value = baseDate.getFullYear();

    setTimeout(() => {
      if (yearViewContainer.value) {
        yearViewContainer.value!.scrollTo({ left: 0, top: 99999 });
      }
    }, 50);
  }

  updateInputValueByTabKey(tabKey);
}

function onDateChange(value: AdvancedDatePickerValue): void {
  if (disabled.value) {
    return;
  }

  if (!value) {
    localValue.value = null;
    inputValue.value = '';
    emit('update:modelValue', value);
    return;
  }

  emit('update:modelValue', value);
}

const yearPattern = `^${yearRegex}$`;
const monthYearPattern = `^${monthRegex}/${yearRegex}$`;
const dmyPattern = `^${dayRegex}/${monthRegex}/${yearRegex} - ${dayRegex}/${monthRegex}/${yearRegex}$`;

const useDebounce = _.debounce(() => {
  isInputting.value = false;
}, 1000);

function onInputValue(event: KeyboardEvent): void {
  isInputting.value = true;
  isSelectedDateOutOfRange.value = false;
  useDebounce();

  const { value } = (event.target as HTMLInputElement);

  if (new RegExp(yearPattern).test(value)) {
    const year = parseInt(value, 10);

    if ((maxDate.value && year > maxDate.value.getFullYear()) || (minDate.value && year < minDate.value.getFullYear())) {
      isSelectedDateOutOfRange.value = true;
      return;
    }

    localValue.value = year;
    currentTab.value = 'year';
    return;
  }

  const monthYearMatch = value.match(new RegExp(monthYearPattern));
  if (monthYearMatch) {
    const [, month, year] = monthYearMatch;
    const parsedMonth = parseInt(month, 10) - 1;
    const parsedYear = parseInt(year, 10);

    isSelectedDateOutOfRange.value = checkIfMonthYearOutOfRange(parsedMonth, parsedYear);

    if (isSelectedDateOutOfRange.value) {
      return;
    }

    localValue.value = { month: parsedMonth, year: parsedYear };
    currentTab.value = 'month';
    return;
  }

  const dayMonthYearRangeMatch = value.match(new RegExp(dmyPattern));

  if (dayMonthYearRangeMatch) {
    const [, day1, month1, year1, day2, month2, year2] = dayMonthYearRangeMatch;
    const [iDay1, iMonth1, iYear1, iDay2, iMonth2, iYear2] = [day1, month1, year1, day2, month2, year2].map((string) => parseInt(string, 10));

    const startDate = new Date(iYear1, iMonth1 - 1, iDay1);
    const endDate = new Date(iYear2, iMonth2 - 1, iDay2);

    isSelectedDateOutOfRange.value = checkIfFullDateOutOfRange(startDate) || checkIfFullDateOutOfRange(endDate);

    if (isSelectedDateOutOfRange.value) {
      return;
    }

    localValue.value = [startDate, endDate];
    currentTab.value = 'dateRange';
  }
}

function checkIfInputError(): boolean {
  if (!inputValue.value || isInputting.value) {
    return false;
  }

  return isSelectedDateOutOfRange.value || [yearPattern, monthYearPattern, dmyPattern].every((pattern) => !new RegExp(pattern).test(inputValue.value));
}

function getYearList(): number[] {
  const min = minDate.value ? minDate.value.getFullYear() : DEFAULT_MIN_YEAR;
  return Array.from({ length: now.value.getFullYear() - min + 1 }, (_unused, i) => now.value.getFullYear() - i).toReversed();
}

function onKeyDown(e: KeyboardEvent) {
  /**
   * This handler allows using keyboard's left/right arrows to change a cursor position.
   * This basic functionality has gone because of the VueDatePicker.
   * */
  const { currentTarget, code } = e;

  if (!currentTarget || !(currentTarget instanceof HTMLInputElement)) {
    return;
  }

  if (code === 'ArrowLeft') {
    e.stopPropagation();
    e.preventDefault();
    currentTarget.setSelectionRange(
      currentTarget.selectionStart,
      (currentTarget.selectionStart ?? 0) - 1,
    );
    return;
  }

  if (code === 'ArrowRight') {
    e.stopPropagation();
    currentTarget.setSelectionRange(
      currentTarget.selectionStart,
      (currentTarget.selectionStart ?? 0) + 1,
    );
    return;
  }

  if (code === 'Enter' || code === 'NumpadEnter') {
    isInputting.value = false;

    if (checkIfInputError() || isSelectedDateOutOfRange.value) {
      return;
    }

    emit('update:modelValue', localValue.value);
  }
}

onMounted(() => {
  years.value = getYearList();
});

watch(modelValue, (newValue) => {
  localValue.value = newValue;
});

watch(localValue, () => updateInputValueByTabKey(currentTab.value));
// eslint-disable-next-line no-return-assign
watch(dateType, (type) => currentTab.value = type, { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Datepicker), {
        enableTimePicker: false,
        disabled: _unref(disabled),
        placeholder: "Filter by date...",
        autoApply: "",
        modelValue: localValue.value,
        "onUpdate:modelValue": [
          _cache[3] || (_cache[3] = ($event: any) => ((localValue).value = $event)),
          onDateChange
        ],
        minDate: _unref(minDate),
        maxDate: _unref(maxDate),
        ref_key: "datePickerRef",
        ref: datePickerRef,
        format: displayFormat.value,
        class: "date-input",
        weekStart: "0",
        monthPicker: currentTab.value === 'month',
        yearPicker: currentTab.value === 'year',
        range: currentTab.value === 'dateRange',
        monthChangeOnArrows: false,
        onClosed: _cache[4] || (_cache[4] = () => {
          if (checkIfInputError()) {
            emit('update:modelValue', null);
          }
        })
      }, {
        "month-year": _withCtx(({ month, year, months, handleMonthYearChange }) => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[5] || (_cache[5] = _createElementVNode("label", {
                  for: "target-date",
                  class: "target-date-input-label"
                }, "Target Date", -1)),
                _withDirectives(_createElementVNode("input", {
                  id: "target-date",
                  class: _normalizeClass(['target-date-input', { error: checkIfInputError() }]),
                  placeholder: "Try: 1/2025",
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
                  onKeydown: onKeyDown,
                  onInput: _withModifiers(onInputValue, ["prevent"]),
                  onFocus: _cache[1] || (_cache[1] = () => {
                    checkIfInputError();
                    isInputting.value = true;
                  }),
                  onBlur: _cache[2] || (_cache[2] = () => {
                    isInputting.value = false;
                    checkIfInputError();
                  })
                }, null, 34), [
                  [_vModelText, inputValue.value]
                ]),
                (checkIfInputError())
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(isSelectedDateOutOfRange.value ? _unref(t)('validation:advDatePicker.outOfRange') : _unref(t)('validation:advDatePicker.wrongFormat')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tabs, (tab) => {
                  return _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(['btn', 'tab-button', { active: currentTab.value === tab.key }]),
                    key: tab.key,
                    onClick: () => onClickTab(tab.key)
                  }, _toDisplayString(tab.displayName), 11, _hoisted_8)
                }), 64))
              ])
            ]),
            (currentTab.value === 'dateRange')
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, _toDisplayString(getDisplayMonthYear(month, year)), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("button", {
                      type: "button",
                      class: "btn change-button",
                      onClick: () => handleMonthYearChange?.(false, true)
                    }, _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "far fa-chevron-left" }, null, -1)
                    ]), 8, _hoisted_12),
                    _createElementVNode("button", {
                      type: "button",
                      class: "change-button",
                      onClick: () => handleMonthYearChange?.(true, true)
                    }, _cache[7] || (_cache[7] = [
                      _createElementVNode("i", { class: "far fa-chevron-right" }, null, -1)
                    ]), 8, _hoisted_13)
                  ])
                ]))
              : _createCommentVNode("", true),
            (currentTab.value === 'month')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  ref_key: "monthViewContainer",
                  ref: monthViewContainer,
                  class: "month-view-container fade-in"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(years.value, (year) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "year-group",
                      key: year
                    }, [
                      _createElementVNode("p", _hoisted_14, _toDisplayString(year), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(months, (monthGroup, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "month-group",
                          key: `month-group-${index}`
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(monthGroup, (monthItem) => {
                            return (_openBlock(), _createElementBlock("button", {
                              key: monthItem.value,
                              type: "button",
                              disabled: checkIfMonthYearOutOfRange(monthItem.value, year),
                              class: _normalizeClass(['btn', 'btn-month-text', {
                      active: localValue.value.month === monthItem.value && localValue.value.year === year,
                    }]),
                              onClick: () => {
                      if (checkIfMonthYearOutOfRange(monthItem.value, year)) {
                        return;
                      }

                      emit('update:modelValue', {
                        month: monthItem.value,
                        year,
                      });
                    }
                            }, _toDisplayString(monthItem.text), 11, _hoisted_15))
                          }), 128))
                        ]))
                      }), 128))
                    ]))
                  }), 128))
                ], 512))
              : _createCommentVNode("", true),
            (currentTab.value === 'year')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  ref_key: "yearViewContainer",
                  ref: yearViewContainer,
                  class: "year-view-container fade-in"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(years.value, (year) => {
                    return (_openBlock(), _createElementBlock("button", {
                      class: _normalizeClass([['btn', 'btn-year-text', { active: typeof localValue.value === 'number' && localValue.value === year }], "btn btn-year-text"]),
                      onClick: () => emit('update:modelValue', year),
                      key: year
                    }, _toDisplayString(year), 11, _hoisted_16))
                  }), 128))
                ], 512))
              : _createCommentVNode("", true)
          ])
        ]),
        "input-icon": _withCtx(() => _cache[8] || (_cache[8] = [
          _createElementVNode("i", { class: "fas fa-calendar-days date-picker-icon" }, null, -1)
        ])),
        _: 1
      }, 8, ["disabled", "modelValue", "minDate", "maxDate", "format", "monthPicker", "yearPicker", "range"])
    ])
  ]))
}
}

})