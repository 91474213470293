import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "upload-video-source-container" }
const _hoisted_2 = {
  key: 0,
  class: "description"
}
const _hoisted_3 = {
  key: 1,
  class: "description"
}

import _ from 'lodash';
import { computed, onBeforeUnmount, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { Video } from '@/modules/shared/types/video.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import VideoUploaderDraggableUI from '../../molecules/videoUploaderDraggableUI/VideoUploaderDraggableUI.vue';
import VideoUploaderBox from '../../molecules/videoUploaderBox/VideoUploaderBox.vue';
import { useStore } from '../../../store/hooks';

interface ReUploadVideoSourceFileModalProps {
  onClose: () => void;
  video: Video | null | undefined;
  /** @default false */
  isReplaceMode?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ReUploadVideoSourceFileModal',
  props: {
    onClose: {},
    video: {},
    isReplaceMode: { type: Boolean, default: false }
  },
  emits: ["confirm"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();
const store = useStore();

const props = __props;
const { onClose, video, isReplaceMode } = toRefs(props);

const emit = __emit;

const isEmptyVideoFiles = computed(() => store.getters.isEmptyVideoFiles);
const isEmptyVideoFilesValid = computed(() => store.getters.isEmptyVideoFilesValid);
const currentVideoTitle = computed(() => _.get(video.value, 'title', ''));

async function onUploadVideo() {
  /**
   * On a normal uploading flow, there will be an upload summary modal.
   * On this flow, skips that modal by directly setting the modal as minimized.
   * This will let the VideoUploadProgressPopup (the bottom-right progress popup) display.
   * */
  store.commit('setIsFirstTimeUploadSummaryMinimized', true);
  store.commit('setIsUploadSummaryMinimized', true);

  const payload = { videoObject: [video.value] };
  await store.dispatch('reuploadVideoSourceFile', payload);

  onClose.value();
  emit('confirm');
}

onBeforeUnmount(() => {
  store.dispatch('destroyVideoFiles');
});

return (_ctx: any,_cache: any) => {
  const _directive_video_file_draggable = _resolveDirective("video-file-draggable")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(VideoUploaderDraggableUI),
    _createVNode(Modal, {
      title: _unref(isReplaceMode) ? _unref(t)('video.replaceSourceFile.title') : _unref(t)('reUploaderModal.title'),
      primaryButtonText: _unref(t)('common:button.upload'),
      isDisabledPrimaryButton: isEmptyVideoFilesValid.value,
      onClickPrimaryButton: onUploadVideo,
      onModalClose: _unref(onClose)
    }, {
      body: _withCtx(() => [
        (_unref(isReplaceMode))
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(t)('video.replaceSourceFile.description')), 1))
          : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(t)('video.uploader.retryCurrentFile', { currentVideoTitle: currentVideoTitle.value })), 1)),
        _createElementVNode("div", {
          class: _normalizeClass([{empty: isEmptyVideoFiles.value}, "video-uploader-container"])
        }, [
          _createVNode(VideoUploaderBox, { isMultiple: false })
        ], 2)
      ]),
      _: 1
    }, 8, ["title", "primaryButtonText", "isDisabledPrimaryButton", "onModalClose"])
  ])), [
    [_directive_video_file_draggable, { isMultiple: false }]
  ])
}
}

})