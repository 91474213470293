<template>
  <SectionItem
    :title="key === 'default' ? 'Additional Information' : key"
    v-for="(fields, key) in groupedFields"
    :id="key"
    :key="key">
    <Field
      :title="field.displayName"
      :subtitle="field.description"
      v-for="field in fields"
      :key="field.displayName"
    >
      <div class="wrapper">
        <DateTimeSelector
          v-if="field.type === 'dateTime'"
          :disabled="isViewer"
          :modelValue="field.value"
          v-model="field.value" />
        <Checkbox
          v-else-if="field.type === 'switch'"
          :disabled="isViewer"
          :id="field.displayName"
          v-model.number="field.value" />
        <CustomFieldLabelSelector
          v-else-if="field.type === 'labels'"
          :disabled="isViewer"
          @onAddLabel="(value) => addLabel(value, field)"
          v-model="field.value"
          :field-id="field.id" />
        <Input
          v-else-if="field.type === 'number'"
          :disabled="isViewer"
          inputType="number"
          maxWidth="180px"
          v-model.number="field.value" />
        <Input
          v-else
          inputType="text"
          :disabled="isViewer"
          v-model="field.value" />
      </div>
    </Field>
  </SectionItem>
</template>

<script>
import _ from 'lodash';
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { useRoute } from 'vue-router';

// import CustomFieldDateSelector from '@/components/molecules/customFieldDateSelector/CustomFieldDateSelector.vue';
// import FormGroupTemplate from '@/modules/shared/components/atoms/formGroupTemplate/FormGroupTemplate.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import Checkbox from '@/modules/shared/components/atoms/checkbox/Checkbox.vue';
import SectionItem from '@/modules/shared/components/molecules/sectionItem/SectionItem.vue';
import DateTimeSelector from '@/modules/shared/components/atoms/dateTimeSelector/DateTimeSelector.vue';
import Field from '@/modules/shared/components/molecules/field/Field.vue';
import CustomFieldLabelSelector from '../../molecules/customFieldLabelSelector/CustomFieldLabelSelector.vue';
import { useStore } from '../../../store/hooks';

export default {
  components: {
    CustomFieldLabelSelector,
    // CustomFieldDateSelector,
    // FormGroupTemplate,
    Input,
    Checkbox,
    SectionItem,
    Field,
    DateTimeSelector,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const customFields = ref([]);

    const allCustomFields = computed(() => store.state.customField.customFieldList);
    const currentVideo = computed(() => store.state.video.currentVideo);
    const groupedFields = computed(() => _.groupBy(customFields.value, 'sectionName'));
    const hash = computed(() => route.hash);

    const memberships = computed(() => store.state.project.currentProject.memberships);
    const currentUser = computed(() => store.state.user.currentUser);
    const isViewer = computed(() => {
      const user = memberships.value?.find((member) => member.accountId === currentUser.value?.id);
      return !(user.projectRole === 'admin' || user.projectRole === 'uploader' || user.projectRole === 'project-manager');
    });

    const getCfDefaultValue = (fieldType) => {
      const type = fieldType.toLowerCase();

      if (type === 'switch') {
        return false;
      }
      if (type === 'labels') {
        return [];
      }
      return '';
    };

    const getFieldValue = (field, type) => {
      if (!field) {
        return getCfDefaultValue(type);
      }

      if (type === 'labels') {
        const labels = _.get(field, 'labelsValue.labelNames', []);
        // Remove null from labels
        return labels.filter((label) => label);
      }

      return field[`${type}Value`];
    };

    const mapCustomFieldsValue = () => {
      const customFieldEntries = _.get(currentVideo, 'value.customFieldEntries');
      allCustomFields.value?.forEach((section) => {
        section.customFields.forEach((field) => {
          // Find the custom field value that is already existed in the video
          const existingItem = customFieldEntries?.find((item) => item.field.fieldName === field.fieldName);

          customFields.value.push(
            {
              id: field.id,
              fieldName: field.fieldName,
              displayName: field.displayName,
              description: field.description,
              type: field.type,
              value: getFieldValue(existingItem, field.type),
              sectionName: section.displayName,
            },
          );
        });
      });
    };

    onMounted(async () => {
      if (!allCustomFields.value) {
        await store.dispatch('loadCustomFieldListPromise');
      }

      // Map all sections into the new customFields array
      if (allCustomFields.value) {
        mapCustomFieldsValue();
      }

      if (hash.value) {
        const timer = setTimeout(() => {
          const id = hash.value.slice(1); // Remove #
          const hashElement = document.getElementById(id);
          if (hashElement) {
            const yOffset = -100;
            const y = hashElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
          clearTimeout(timer);
        }, 200);
      }
    });

    const addLabel = (newLabel, field) => {
      if (field.value) {
        // ถ้าฟิลด์ปัจจุบันไม่ใช่ undefined ให้ push label ใหม่เข้าไป
        field.value.push(newLabel);
      } else {
        // ถ้าฟิลด์ปัจจุบันเป็น undefined ให้สร้าง Array ใหม่ที่มีค่านี้ 1 ค่า
        // eslint-disable-next-line no-param-reassign
        field.value = [newLabel];
      }
    };

    watch(() => customFields.value, () => {
      // If any fields were modified, update them to the store
      store.dispatch('setCustomFields', customFields.value);
    }, { deep: true });

    // in case of reset
    // watch(() => currentVideo.value?.customFields, () => {
    //   customFields.value = [];
    //   mapCustomFieldsValue();
    // }, { deep: true });

    return {
      addLabel,
      customFields,
      groupedFields,
      isViewer,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables";
@import "~@/assets/scss/breakpoint";

.form-input.form-input-row-container {
  margin-bottom: 1.5rem;
}

.section-group {
  scroll-margin-top: 100px;
}

.wrapper {
  width: 80%;

  @media screen and (max-width: $max-layout-md) {
    width: 100%;
  }
}

::v-global(.dp__input) {
  font-family: "Roboto", "Noto Sans Thai", sans-serif;
  color: $grey-800;
  font-size: 14px;
  padding-bottom: 4px;
}

::v-global(.multiselect__option--highlight),
::v-global(.multiselect__option--highlight::after) {
  background-color: $ci-primary;
}

:deep(.section-item-header .title) {
  padding: 8px 16px 6px;
  background-color: $grey-200;
  border-radius: 3px;
  display: block;
}

:deep(.section-item-header .title-container) {
  width: 100%;
}

.section-title {
  background-color: $bg-navy;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
  font-weight: $font-weight-bold;
  margin-top: 48px;
  margin-bottom: 24px;
  padding: 8px 16px;
}

.help-text {
  color: #9ea4a9;
  font-size: 12px;
  margin-top: 4px;
}

.checkbox-group {
  .form-check-label {
    cursor: pointer;
    font-size: 14px;
    color: #6c757d;
  }
  .form-check-input {
    cursor: pointer;
    margin-top: 0.1rem;
  }
}

</style>
