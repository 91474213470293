import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "date-input-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "time-input-wrapper"
}

import Datepicker, { type PublicMethods } from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, toRefs } from 'vue';

interface DateTimeSelectorProps {
  disabled?: boolean;
  modelValue: Date | string;
  minDate?: Date | string;
  maxDate?: Date | string;
  disableTimeSelector?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DateTimeSelector',
  props: {
    disabled: { type: Boolean },
    modelValue: {},
    minDate: {},
    maxDate: {},
    disableTimeSelector: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  disabled,
  modelValue,
  minDate,
  maxDate,
  disableTimeSelector,
} = toRefs(props);

const emit = __emit;

const timeInput = ref<PublicMethods | null>(null);
const date = ref<Date | null>(modelValue.value ? new Date(modelValue.value) : null);
const time = ref<{ hours: number; minutes: number } | null>(modelValue.value ? {
  hours: new Date(modelValue.value).getHours(),
  minutes: new Date(modelValue.value).getMinutes(),
} : null);

function onDateChange(value: Date | null) {
  if (disabled.value) {
    return;
  }

  if (!value) {
    date.value = null;
    emit('update:modelValue', value);
    return;
  }

  emit('update:modelValue', value.toISOString());

  if (value && timeInput.value && !disableTimeSelector.value) {
    timeInput.value.openMenu();
  }
}

function onTimeChange(value: { hours: number; minutes: number; seconds: number } | null) {
  if (disabled.value || disableTimeSelector.value) {
    return;
  }

  if (value) {
    if (!date.value) {
      date.value = new Date();
    }
    const updatedDate = date.value;
    updatedDate.setHours(value.hours, value.minutes, value.seconds, 0);
    emit('update:modelValue', updatedDate.toISOString());
  } else {
    date.value = null;
    emit('update:modelValue', value);
  }
}

// function onTimeClose() {
//   if (date.value && timeInput.value) {
//     timeInput.value.selectDate();
//   }
// }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Datepicker), {
        enableTimePicker: false,
        disabled: _unref(disabled),
        placeholder: "dd/mm/yyyy",
        autoApply: "",
        modelValue: date.value,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((date).value = $event)),
          onDateChange
        ],
        minDate: _unref(minDate),
        maxDate: _unref(maxDate),
        ref: "dateInput",
        format: "dd/MM/yyyy",
        class: "date-input",
        weekStart: "0"
      }, {
        "input-icon": _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "fas fa-calendar-days date-picker-icon" }, null, -1)
        ])),
        _: 1
      }, 8, ["disabled", "modelValue", "minDate", "maxDate"])
    ]),
    (!_unref(disableTimeSelector))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_unref(Datepicker), {
            timePicker: "",
            placeholder: "HH:mm",
            modelValue: time.value,
            "onUpdate:modelValue": [
              _cache[1] || (_cache[1] = ($event: any) => ((time).value = $event)),
              onTimeChange
            ],
            ref_key: "timeInput",
            ref: timeInput,
            disabled: _unref(disabled)
          }, {
            "input-icon": _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "fas fa-clock date-picker-icon" }, null, -1)
            ])),
            _: 1
          }, 8, ["modelValue", "disabled"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})