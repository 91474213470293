import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-chrome" }

import _ from 'lodash';
import { computed, watch } from 'vue';
import { useStore } from '@/store';
import { VideoUploadProgressPopup, UploadSummaryModal, type VideoUploadState } from '@/modules/videoUpload';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppChrome',
  setup(__props) {

const store = useStore<{videoUpload: VideoUploadState}>();

const hasUploadQueue = computed(() => !_.isEmpty(_.get(store, 'state.videoUpload.videoUploadKeys', [])));
const videoKeysGroupByState = computed(() => store.state.videoUpload.videoKeysGroupByState);
const isUploadSummaryMinimized = computed(() => store.state.videoUpload.isUploadSummaryMinimized);
const isFirstTimeUploadSummaryMinimized = computed(() => store.state.videoUpload.isFirstTimeUploadSummaryMinimized);
const uploadingVideoKeys = computed(() => _.get(videoKeysGroupByState.value, 'uploading', []));
const isUploadingVideos = computed(() => !_.isEmpty(uploadingVideoKeys.value));

function handleBeforeCloseBrowser(event: Event) {
  if (isUploadingVideos.value) {
    event.preventDefault();
    // Older browsers supported custom message
    // eslint-disable-next-line no-param-reassign
    event.returnValue = true;
    return 'onbeforeunload';
  }

  return undefined;
}

watch(isUploadingVideos, (isUploading) => {
  window.removeEventListener('beforeunload', handleBeforeCloseBrowser);
  window.removeEventListener('unload', handleBeforeCloseBrowser);

  if (isUploading) {
    window.addEventListener('beforeunload', handleBeforeCloseBrowser);
    window.addEventListener('unload', handleBeforeCloseBrowser);
  }
}, { immediate: true });

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    (!isUploadSummaryMinimized.value)
      ? (_openBlock(), _createBlock(_unref(UploadSummaryModal), { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "slide" }, {
      default: _withCtx(() => [
        (hasUploadQueue.value && isFirstTimeUploadSummaryMinimized.value)
          ? (_openBlock(), _createBlock(_unref(VideoUploadProgressPopup), { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
}

})