import qs from 'qs';
import { qsOption } from '@/services/constants';
import { axiosAPI } from '@/plugins/axios';
import type { CustomField, GetCustomFieldSectionsFilter } from '../types';

interface CustomFieldSectionPayload {
  displayName: string;
}

export const createCustomField = (payload: CustomField) => axiosAPI.post('/custom-fields', payload);
export const getCustomFieldById = (id: string) => axiosAPI.get(`/custom-fields/${id}`);
export const deleteCustomFieldById = (id: string) => axiosAPI.delete(`/custom-fields/${id}`);
export const updateCustomFieldById = (id: string, payload: CustomField) => axiosAPI.put(`/custom-fields/${id}`, payload);

export const getCustomFieldSections = (filter?: GetCustomFieldSectionsFilter) => {
  const query = qs.stringify(filter, qsOption);
  return axiosAPI.get(`/custom-field-sections${query}`);
};

export const createCustomFieldSection = (payload: CustomFieldSectionPayload) => axiosAPI.post('/custom-field-sections', payload);
export const updateCustomFieldSectionName = (sectionId: string, payload: CustomFieldSectionPayload) => (
  axiosAPI.put(`/custom-field-sections/${sectionId}/display-name`, payload)
);
export const reorderCustomFields = (payload: CustomField[]) => axiosAPI.put('/custom-field-sections', payload);
export const deleteCustomFieldSection = (sectionId: string) => axiosAPI.delete(`/custom-field-sections/${sectionId}`);
export const loadCustomFieldLabels = (filter?: { customFieldId: string }) => {
  const query = qs.stringify(filter, qsOption);
  return axiosAPI.get(`/custom-field-labels${query}`);
};
