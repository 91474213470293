import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"
import _imports_0 from '../../../../../assets/images/collection-icon.svg'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "select-project"
}
const _hoisted_3 = { class: "select-title" }
const _hoisted_4 = { class: "select-wrapper" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "subtitle" }
const _hoisted_7 = {
  key: 2,
  class: "search-container"
}
const _hoisted_8 = {
  key: 0,
  class: "content-container"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  key: 0,
  class: "video-image"
}
const _hoisted_11 = {
  key: 0,
  class: "video-duration"
}
const _hoisted_12 = { class: "content-title" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = {
  key: 2,
  class: "video-count"
}

import _ from 'lodash';
import { Filter } from '@/modules/shared/types/axios.type';
import { SelectedContent, SelectedContentType } from '@/modules/bumperDisplay/types';
import { Project } from '@/modules/shared/types/project.type';
import { VideoUploadByKeyObject } from '@/modules/videoUpload/types/videoUpload.type';
import {
  computed, ref, watch, onBeforeMount, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { loadProjectsAPI } from '@/modules/shared/services/project';
import { getCollections } from '@/modules/collectionList';
import { loadVideosAPI } from '@/modules/projectVideoList';
import {
  isVideoProcessing,
  getVideoDuration,
  getVideoUploadCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Pagination from '@/modules/shared/components/molecules/pagination/Pagination.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useStore } from '../../../store/hooks';

interface SelectBumperContentModalProps {
  selectedContentType: SelectedContentType;
  projectKey?: string;
  submitButtonTitle?: string;
  secondaryButtonTitle?: string;
  isSelectVideoSource?: boolean;
  onSubmit: (value: SelectedContent) => Promise<void>;
}

type ErrorMessages = Record<SelectedContentType, {[key: string]: string}>;


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectBumperContentModal',
  props: {
    selectedContentType: {},
    projectKey: {},
    submitButtonTitle: {},
    secondaryButtonTitle: {},
    isSelectVideoSource: { type: Boolean },
    onSubmit: { type: Function }
  },
  emits: ["modalClose", "update:projectKey", "update:currentStep"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const {
  selectedContentType,
  projectKey,
  submitButtonTitle,
  secondaryButtonTitle,
  isSelectVideoSource,
  onSubmit,
} = toRefs(props);

const { t } = useI18n();
const route = useRoute();

const emit = __emit;

const store = useStore();
const projects = ref<Array<Project>>([]);
const contents = ref<Array<VideoUploadByKeyObject | Project>>([]);
const selectedProject = ref<Project | null>(null);
const selectedContent = ref<VideoUploadByKeyObject | Project | null>(null);
const contentLastPage = ref<number>(1);
const loadContentsState = ref<string>('loading');
const searchTerm = ref<string>('');
const refreshVideoTimer = ref<number | null>(null);
const isNotInProject = ref<boolean>(false);

const teamName = computed(() => route.params.teamName);
const currentUser = computed(() => store.state.user.currentUser);
const computedPrimaryButtonTitle = computed(
  () => submitButtonTitle.value || t('common:button.next'),
);
const computedSecondaryButtonTitle = computed(
  () => secondaryButtonTitle.value || t('common:button.cancel'),
);
const selectedProjectKey = computed<string | undefined>(() => selectedProject.value?.key ?? projectKey.value);

const loadProjects = async (filter: Filter = {}) => {
  loadContentsState.value = 'loading';
  try {
    const { data } = await loadProjectsAPI(filter);
    if (!data) {
      loadContentsState.value = 'error';
      return;
    }

    if (selectedContentType.value === 'project') {
      contents.value = data.data;
      loadContentsState.value = 'success';
    } else {
      const projectList = data.data;
      projects.value = projectList.map((project: Project) => ({
        ...project,
        nameWithCount: `${project.name} (${project.videoCount} videos)`,
      }));

      if (projectKey.value) {
        selectedProject.value = projects.value.find((project) => project.key === projectKey.value) ?? null;
      } else if (!_.isEmpty(projects.value)) {
        // eslint-disable-next-line prefer-destructuring
        selectedProject.value = projects.value[0];
      }

      isNotInProject.value = Boolean(projectKey.value) && !selectedProject.value;
    }
  } catch (error) {
    loadContentsState.value = 'error';
  }
};
const loadVideos = async (filter: Filter = {}) => {
  if (!selectedProjectKey.value) {
    return;
  }

  loadContentsState.value = 'loading';
  try {
    const { data } = await loadVideosAPI({ ...filter, projectKey: selectedProjectKey.value, limit: '20' });
    contents.value = data?.data.map((item) => ({ ...item, $isDisabled: isVideoProcessing(item) }));
    contentLastPage.value = data?.lastPage;
    loadContentsState.value = 'success';
  } catch (error) {
    loadContentsState.value = 'error';
  }
};

const loadCollections = async (filter: Filter = {}) => {
  if (!selectedProjectKey.value) {
    return;
  }

  loadContentsState.value = 'loading';
  try {
    const { data } = await getCollections(selectedProjectKey.value, { ...filter, limit: '20' });
    contents.value = data?.data;
    contentLastPage.value = data?.lastPage;
    loadContentsState.value = 'success';
  } catch (error) {
    loadContentsState.value = 'error';
  }
};

const loadContents = async (filter: Filter = {}) => {
  switch (selectedContentType.value) {
    case 'video':
      await loadVideos(filter);
      break;
    case 'contentCollection':
      await loadCollections(filter);
      break;
    default:
  }
};

const onClickUploadVideo = () => {
  if (!selectedProject.value) {
    return;
  }

  refreshVideoTimer.value = setInterval(() => {
    loadVideos();
  }, 15000);

  window.open(`/${teamName.value}/projects/${selectedProject.value?.key}/upload`, '_blank');
};

const onSearch = (event: Event) => {
  if (event.target && event.target instanceof HTMLInputElement) {
    searchTerm.value = event.target.value;
  }
  // always keep tab value for parent SetBumperScheduleModal
  // router.push({ query: { tab: route.query.tab, q: event.target.value } });
};

const onClearSearch = () => {
  searchTerm.value = '';

  // always keep tab value for parent SetBumperScheduleModal
  // router.push({ query: { tab: route.query.tab } });
};

const errorMessages = computed<ErrorMessages>(() => ({
  project: {
    name: 'project',
    iconClassName: 'fas fa-briefcase',
    description:
      'Either your team has no project or you don’t have permission to view it. Ask your team to update permissions or create a new project for your contents.',
    buttonStyle: 'text-primary',
    buttonTitle: 'Create new project',
    buttonPrefixIconClassName: 'fas fa-plus',
  },
  contentCollection: {
    name: 'collection',
    description: `Project '${selectedProject.value?.name}' has no collection yet. Start creating a collection to group your contents.`,
    buttonStyle: 'text-primary',
    buttonTitle: 'Create new collection',
    buttonPrefixIconClassName: 'fas fa-plus',
  },
  video: {
    name: 'video',
    iconClassName: 'fas fa-play-circle',
    description: `Project ‘${selectedProject.value?.name}’ has no video yet. Start uploading your contents.`,
    buttonStyle: 'primary',
    buttonTitle: 'Upload',
    buttonPrefixIconClassName: 'fas fa-cloud-upload-alt',
  },
}));

const onCreateNewContent = (contentType: string) => {
  if (contentType === 'project') {
    window.open(`/${teamName.value}/projects/create`, '_blank');
  } else if (contentType === 'contentCollection') {
    window.open(`/${teamName.value}/projects/${selectedProjectKey.value}/collections`, '_blank');
  } else if (contentType === 'video') {
    window.open(`/${teamName.value}/projects/${selectedProjectKey.value}/upload`, '_blank');
  }
};

const onChangeProject = (value: Project) => {
  onClearSearch();
  isNotInProject.value = false;
  selectedProject.value = value;
};

const onClickSubmit = () => {
  onSubmit.value({
    type: selectedContentType.value,
    content: { content: selectedContent.value },
    projectKey: selectedProjectKey.value ?? '',
  });
};

/**
 * This function is used for a quick type checking for "content(s)" and "selectedContent" variables.
 *
 * This function should not be moved to the type guard file, because it really quick checks
 * if the [obj] is not a type of Project and null. And may not enough to say that this is a VideoUploadByKeyObject type guard.
 */
function checkIfContentIsNotTypeOfProject(obj: unknown): obj is VideoUploadByKeyObject {
  return typeof obj === 'object' && obj !== null
    && 'duration' in obj
    && 'playable' in obj
    && 'playbackUrls' in obj
    && 'vod' in obj;
}

onBeforeMount(async () => {
  // load project list
  await loadProjects();
});

watch(searchTerm, () => {
  if (selectedContentType.value === 'project') {
    loadProjects({ q: searchTerm.value });
  } else {
    loadContents({ q: searchTerm.value });
  }
});

watch(
  () => route.query?.page,
  () => {
    if (selectedContentType.value === 'project') {
      loadProjects({ q: searchTerm.value, page: route.query?.page });
    } else {
      loadContents({ q: searchTerm.value, page: route.query?.page });
    }
  },
);

watch(selectedProjectKey, () => loadContents(), { immediate: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Modal, {
    primaryButtonText: computedPrimaryButtonTitle.value,
    isDisabledPrimaryButton: !selectedContent.value,
    secondaryButtonText: computedSecondaryButtonTitle.value,
    onModalClose: _cache[2] || (_cache[2] = () => emit('modalClose')),
    onClickPrimaryButton: onClickSubmit,
    onClickSecondaryButton: () => computedSecondaryButtonTitle.value === _unref(t)('common:button.back')
      ? emit('update:currentStep')
      : emit('modalClose')
    
  }, _createSlots({
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_unref(selectedContentType) !== 'project')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('glossary:project')), 1),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(SelectOption, {
                  options: projects.value,
                  "model-value": selectedProject.value,
                  placeholder: _unref(t)('bumper.create.selectProject.placeholder'),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = (value) => onChangeProject(value)),
                  label: "nameWithCount"
                }, null, 8, ["options", "model-value", "placeholder"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(isSelectVideoSource)
              ? _unref(t)('bumper.create.selectVideoSource.title')
              : ` ${_unref(t)('common:choose')} ${
                _unref(selectedContentType) === 'contentCollection' ? 'collection' : _unref(selectedContentType)
              }`), 1),
        _createElementVNode("div", _hoisted_6, [
          _createTextVNode(_toDisplayString(_unref(isSelectVideoSource) ?
            _unref(t)('bumper.create.selectVideoSource.description'):
            _unref(t)('bumperSchedule.selectContent.description', {
              name:
                _unref(selectedContentType) === 'contentCollection' ? 'collection' : _unref(selectedContentType),
            })) + " ", 1),
          (_unref(selectedContentType) === 'video' && !isNotInProject.value)
            ? (_openBlock(), _createBlock(Button, {
                key: 0,
                type: "button",
                buttonStyle: "link-primary",
                onClick: onClickUploadVideo
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('common:uploadAnotherFile')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        (loadContentsState.value === 'loading')
          ? (_openBlock(), _createBlock(PreLoaderSection, { key: 1 }))
          : _createCommentVNode("", true),
        (loadContentsState.value === 'success' && !(contents.value.length === 0 && !searchTerm.value))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(Input, {
                onPressedEnter: onSearch,
                modelValue: searchTerm.value,
                placeholder: "Search by name",
                isSearchInput: ""
              }, null, 8, ["modelValue"])
            ]))
          : _createCommentVNode("", true),
        (loadContentsState.value === 'success' && contents.value.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (contents.value?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(contents.value, (content) => {
                      return (_openBlock(), _createElementBlock("button", {
                        type: "button",
                        key: content.id,
                        onClick: () =>  {
                selectedContent.value = content;
                if (_unref(selectedContentType) === 'project') {
                  emit('update:projectKey', selectedContent.value?.key);
                }
              },
                        class: _normalizeClass([{ selected: selectedContent.value?.id === content.id }, "btn content-item"])
                      }, [
                        (_unref(selectedContentType) !== 'project' && checkIfContentIsNotTypeOfProject(content))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createVNode(ImageView, {
                                source: _unref(getVideoUploadCoverImage)({ video: content, currentUser: currentUser.value }),
                                imageSize: "small"
                              }, null, 8, ["source"]),
                              (_unref(selectedContentType) !== 'contentCollection' && !_unref(_).isNil(content) && 'duration' in content && !_unref(_).isNil(content.duration))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(getVideoDuration)(content)), 1))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_12, [
                          ('name' in content)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(content.name), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(content.title), 1)),
                          ('videoCount' in content)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_15, " (" + _toDisplayString(content.videoCount) + " videos) ", 1))
                            : _createCommentVNode("", true)
                        ])
                      ], 10, _hoisted_9))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (loadContentsState.value === 'success' && contentLastPage.value > 1)
                ? (_openBlock(), _createBlock(Pagination, {
                    key: 1,
                    "last-page": contentLastPage.value
                  }, null, 8, ["last-page"]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (loadContentsState.value !== 'loading' && isNotInProject.value)
          ? (_openBlock(), _createBlock(MessageBox, {
              key: 4,
              title: _unref(t)('bumperSchedule.edit.noPermission.title'),
              description: _unref(t)('bumperSchedule.edit.noPermission.description'),
              iconClassName: "fas fa-file-lock"
            }, null, 8, ["title", "description"]))
          : (loadContentsState.value === 'success' && contents.value.length === 0 && searchTerm.value)
            ? (_openBlock(), _createBlock(EmptySearch, {
                key: 5,
                keyword: searchTerm.value,
                onClearSearch: onClearSearch
              }, null, 8, ["keyword"]))
            : (loadContentsState.value === 'success' && contents.value.length === 0 && !searchTerm.value)
              ? (_openBlock(), _createBlock(MessageBox, {
                  key: 6,
                  title: `No ${
              _unref(selectedContentType) === 'contentCollection' ? 'collection' : _unref(selectedContentType)
            }`,
                  description: errorMessages.value[`${_unref(selectedContentType)}`]?.description,
                  iconClassName: errorMessages.value[`${_unref(selectedContentType)}`]?.iconClassName,
                  buttonPrefixIconClassName: 
              errorMessages.value[`${_unref(selectedContentType)}`]?.buttonPrefixIconClassName
            ,
                  buttonStyle: errorMessages.value[`${_unref(selectedContentType)}`]?.buttonStyle,
                  buttonTitle: errorMessages.value[`${_unref(selectedContentType)}`]?.buttonTitle,
                  onClickButton: () => onCreateNewContent(_unref(selectedContentType))
                }, _createSlots({ _: 2 }, [
                  (_unref(selectedContentType) === 'contentCollection')
                    ? {
                        name: "icon",
                        fn: _withCtx(() => [
                          _cache[3] || (_cache[3] = _createElementVNode("img", {
                            width: "40",
                            src: _imports_0,
                            alt: "collection"
                          }, null, -1))
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["title", "description", "iconClassName", "buttonPrefixIconClassName", "buttonStyle", "buttonTitle", "onClickButton"]))
              : _createCommentVNode("", true)
      ])
    ]),
    _: 2
  }, [
    (computedPrimaryButtonTitle.value === _unref(t)('common:button.next'))
      ? {
          name: "primary-btn-suffix",
          fn: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-chevron-right" }, null, -1))
          ]),
          key: "0"
        }
      : undefined,
    (computedSecondaryButtonTitle.value === _unref(t)('common:button.back'))
      ? {
          name: "secondary-btn-prefix",
          fn: _withCtx(() => [
            _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-chevron-left" }, null, -1))
          ]),
          key: "1"
        }
      : undefined,
    (computedSecondaryButtonTitle.value !== _unref(t)('common:button.cancel'))
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(Button, {
              buttonStyle: "text-secondary",
              onClick: _cache[1] || (_cache[1] = () => emit('modalClose'))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('common:button.cancel')), 1)
              ]),
              _: 1
            })
          ]),
          key: "2"
        }
      : undefined
  ]), 1032, ["primaryButtonText", "isDisabledPrimaryButton", "secondaryButtonText", "onClickSecondaryButton"]))
}
}

})