<template>
  <div
    class="upload-video-source-container"
    v-video-file-draggable="{ isMultiple: false }">
    <VideoUploaderDraggableUI />
    <Modal
      :title="isReplaceMode ? t('video.replaceSourceFile.title') : t('reUploaderModal.title')"
      :primaryButtonText="t('common:button.upload')"
      :isDisabledPrimaryButton="isEmptyVideoFilesValid"
      :onClickPrimaryButton="onUploadVideo"
      @modalClose="onClose">
      <template v-slot:body>
        <p v-if="isReplaceMode" class="description">{{t('video.replaceSourceFile.description')}}</p>
        <p v-else class="description">{{t('video.uploader.retryCurrentFile', { currentVideoTitle })}}</p>
        <div
          :class="{empty: isEmptyVideoFiles}"
          class="video-uploader-container">
          <VideoUploaderBox :isMultiple="false"/>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { computed, onBeforeUnmount, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { Video } from '@/modules/shared/types/video.type';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import VideoUploaderDraggableUI from '../../molecules/videoUploaderDraggableUI/VideoUploaderDraggableUI.vue';
import VideoUploaderBox from '../../molecules/videoUploaderBox/VideoUploaderBox.vue';
import { useStore } from '../../../store/hooks';

interface ReUploadVideoSourceFileModalProps {
  onClose: () => void;
  video: Video | null | undefined;
  /** @default false */
  isReplaceMode?: boolean;
}

const { t } = useI18n();
const store = useStore();

const props = withDefaults(defineProps<ReUploadVideoSourceFileModalProps>(), {
  isReplaceMode: false,
});
const { onClose, video, isReplaceMode } = toRefs(props);

const emit = defineEmits<{
  confirm: []
}>();

const isEmptyVideoFiles = computed(() => store.getters.isEmptyVideoFiles);
const isEmptyVideoFilesValid = computed(() => store.getters.isEmptyVideoFilesValid);
const currentVideoTitle = computed(() => _.get(video.value, 'title', ''));

async function onUploadVideo() {
  /**
   * On a normal uploading flow, there will be an upload summary modal.
   * On this flow, skips that modal by directly setting the modal as minimized.
   * This will let the VideoUploadProgressPopup (the bottom-right progress popup) display.
   * */
  store.commit('setIsFirstTimeUploadSummaryMinimized', true);
  store.commit('setIsUploadSummaryMinimized', true);

  const payload = { videoObject: [video.value] };
  await store.dispatch('reuploadVideoSourceFile', payload);

  onClose.value();
  emit('confirm');
}

onBeforeUnmount(() => {
  store.dispatch('destroyVideoFiles');
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';
@import '~@/assets/scss/breakpoint';

.upload-video-source-container {
  overflow: visible;
  :deep(.modal-body) {
    overflow: visible;
  }

  .description {
    font-size: 16px;
  }

  .btn-upload {
    display: block;
    width: 100%;
    text-transform: capitalize;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;
  }

  :deep(.error-message-container) {
    .icon {
      font-size: 58px;
    }

    h3 {
      font-size: 16px;
      font-weight: $font-weight-base;
    }
  }
}

.video-uploader-container {
  margin-bottom: 16px;

  &.empty {
    min-height: 200px;
  }
}

.failed-text {
  margin: 0;
}

:deep(.modal-action) {
   .primary{
     text-transform: capitalize;
  }
}

:deep(.upload-file-container) {
  @media screen and (max-width: $max-layout-md) {
    padding: 0;
  }
}
</style>
