import qs from 'qs';
import type {
  AddOrDeleteVideoCollectionPayload,
  Collection,
  CollectionCustomImageField,
} from '@/modules/collection/types';
import type { Video, VideoMetadataItem } from '@/modules/shared/types/video.type';
import type { CustomField } from '@/modules/customField';
import { axiosAPI, axiosAPIv2 } from '@/plugins/axios';
import { qsOption } from '@/services/constants';

const defaultCollectionQuery = {
  includes: [
    'bumperDisplay',
  ],
};

export function getCollectionById(projectKey: string, contentCollectionId: string) {
  const query = qs.stringify({ ...defaultCollectionQuery }, qsOption);
  return axiosAPI.get<Collection>(`/projects/${projectKey}/collections/${contentCollectionId}${query}`);
}

export function deleteCollection(projectKey: string, contentCollectionId: string) {
  return axiosAPI.delete(`/projects/${projectKey}/collections/${contentCollectionId}`);
}

export function updateCollectionListItemsOrder(projectKey: string, contentCollectionId: string, itemListId: string, payload: AddOrDeleteVideoCollectionPayload) {
  return axiosAPI.put(
    `/projects/${projectKey}/collections/${contentCollectionId}/item-lists/${itemListId}/videos/order`,
    payload,
  );
}

export function updateCollectionCustomField(projectKey: string, contentCollectionId: string, payload: { customFields: CustomField[] }) {
  return axiosAPI.put(`/projects/${projectKey}/collections/${contentCollectionId}/custom-fields`, payload);
}

export function updateCollectionCustomImages(projectKey: string, contentCollectionId: string, payload: { customFields: CollectionCustomImageField[] }) {
  return axiosAPI.put(`/projects/${projectKey}/collections/${contentCollectionId}/custom-image-entries`, payload);
}

export function deleteCollectionCustomImage(projectKey: string, contentCollectionId: string, customImageFieldId: string) {
  return axiosAPI.delete(
    `/projects/${projectKey}/collections/${contentCollectionId}/custom-image-entries/${customImageFieldId}`,
  );
}

export function updateCollection(projectKey: string, contentCollectionId: string, payload: Partial<Collection>) {
  return axiosAPI.put<Collection>(`/projects/${projectKey}/collections/${contentCollectionId}`, payload);
}

export function loadCollectionBumper(projectKey: string, contentCollectionId: string) {
  return axiosAPI.get(`/projects/${projectKey}/collections/${contentCollectionId}/bumper-contents`);
}

export function addVideosToCollectionItemList(projectKey: string, contentCollectionId: string, itemListId: string, payload: AddOrDeleteVideoCollectionPayload) {
  return axiosAPI.post<{videos: Video[]}>(
    `/projects/${projectKey}/collections/${contentCollectionId}/item-lists/${itemListId}/videos/add`,
    payload,
  );
}

export function deleteVideosToCollectionItemList(projectKey: string, contentCollectionId: string, itemListId: string, payload: AddOrDeleteVideoCollectionPayload) {
  return axiosAPI.post<{videos: Video[]}>(
    `/projects/${projectKey}/collections/${contentCollectionId}/item-lists/${itemListId}/videos/remove`,
    payload,
  );
}

export function updateCollectionAdditionalMetadata(projectKey: string, contentCollectionId: string, payload: {
  fields: Omit<VideoMetadataItem, 'type' | 'isLinkedFromCollection'>[];
  genres: string[];
}) {
  return axiosAPIv2.put<Collection>(`/projects/${projectKey}/collections/${contentCollectionId}/additional-collection-meta-data`, payload);
}
