/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import { loadVideosAPI } from '@/modules/projectVideoList/services';
import { ProjectVideoListState } from '@/modules/projectVideoList/types/projectVideoList.type';
import type { CursorProjectVideoListQuery } from '@/modules/shared/types/axios.type';
import { formatErrorObject } from '@/modules/shared/utils/errorFormatter';
import {
  setStateLoadingStatusByType,
} from '@/modules/shared/utils/stateManagement';
import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { Video } from '@/modules/shared/types/video.type';
import { LoadingState } from '@/modules/shared/types/state.type';

type ProjectVideoListActionContext = ActionContext<ProjectVideoListState, RootState>;

export const PAGE_ITEM_LIMIT: Readonly<number> = 20;

const initialState = (): ProjectVideoListState => ({
  projectVideoList: null,
  projectVideoTotal: 0,
  loadProjectVideoListState: setStateLoadingStatusByType(),
  pageScrollPosition: 0,
  next: undefined,
  previous: undefined,
});

const state = initialState();

const mutations = {
  setProjectVideoList(state: ProjectVideoListState, videos: Video[]) {
    state.projectVideoList = videos;
  },
  setProjectVideoTotal(state: ProjectVideoListState, total: number) {
    state.projectVideoTotal = total;
  },
  appendProjectVideo(state: ProjectVideoListState, newVideo: Video) {
    if (state.projectVideoList) {
      state.projectVideoList = [newVideo, ...state.projectVideoList];
    } else {
      state.projectVideoList = [newVideo];
    }
  },
  destroyProjectVideoList(state: ProjectVideoListState) {
    Object.assign(state, initialState());
  },
  setLoadProjectVideoListState(state: ProjectVideoListState, loadingState: LoadingState) {
    state.loadProjectVideoListState = setStateLoadingStatusByType(loadingState);
  },
  setPageScrollPosition(state: ProjectVideoListState, position: number) {
    state.pageScrollPosition = position;
  },
  setProjectListVideoNext(state: ProjectVideoListState, value: ProjectVideoListState['next']) {
    state.next = value;
  },
  setProjectListVideoPrevious(state: ProjectVideoListState, value: ProjectVideoListState['previous']) {
    state.previous = value;
  },
};

const actions = {
  async loadProjectVideoList({ commit }: ProjectVideoListActionContext, { filter }: { filter: CursorProjectVideoListQuery }) {
    commit('setLoadProjectVideoListState', { type: 'loading' });
    try {
      const { data } = await loadVideosAPI({
        ...filter,
        limit: filter.limit ?? PAGE_ITEM_LIMIT,
      });

      commit('setProjectVideoList', data.data);
      commit('setProjectVideoTotal', data.total);
      commit('setProjectListVideoNext', data.next);
      commit('setProjectListVideoPrevious', data.previous);
      commit('setLoadProjectVideoListState', { type: 'success' });
    } catch (error) {
      commit('setLoadProjectVideoListState', { type: 'error', error: formatErrorObject(error, 'Videos') });
    }
  },
  destroyProjectVideoList({ commit }: ProjectVideoListActionContext) {
    commit('destroyProjectVideoList');
  },
  appendProjectVideo({ commit }: ProjectVideoListActionContext, video: Video) {
    commit('appendProjectVideo', video);
  },
  setPageScrollPosition({ commit }: ProjectVideoListActionContext, position: number) {
    commit('setPageScrollPosition', position);
  },
};

export default {
  state,
  actions,
  mutations,
};
