import { useStore as baseUseStore } from 'vuex';
import { storeKey } from '@/store';
import type { ProjectState } from '@/modules/shared/types/project.type';
import type { CollectionState, CollectionTypeState } from '@/modules/collection/types';
import type { TeamFeaturesState } from '@/modules/shared/types/teamFeatures.type';
import type { CollectionListState } from '@/modules/collectionList/types';
import type { UserState } from '@/modules/user';

export const useStore = () => baseUseStore<{
  collection: CollectionState;
  collectionList: CollectionListState;
  collectionType: CollectionTypeState;
  project: ProjectState;
  teamFeatures: TeamFeaturesState;
  user: UserState;
}>(storeKey);
