<template>
  <Modal
    :primaryButtonText="computedPrimaryButtonTitle"
    :isDisabledPrimaryButton="!selectedContent"
    :secondaryButtonText="computedSecondaryButtonTitle"
    @modalClose="() => emit('modalClose')"
    :onClickPrimaryButton="onClickSubmit"
    :onClickSecondaryButton="() => computedSecondaryButtonTitle === t('common:button.back')
      ? emit('update:currentStep')
      : emit('modalClose')
    "
  >
    <template v-slot:body>
      <div class="container">
        <div class="select-project" v-if="selectedContentType !== 'project'">
          <div class="select-title">{{ t('glossary:project') }}</div>
          <div class="select-wrapper">
            <SelectOption
              :options="projects"
              :model-value="selectedProject"
              :placeholder="t('bumper.create.selectProject.placeholder')"
              @update:model-value="(value: Project) => onChangeProject(value)"
              label="nameWithCount"
            />
          </div>
        </div>
        <div class="title">
          {{
            isSelectVideoSource
              ? t('bumper.create.selectVideoSource.title')
              : ` ${t('common:choose')} ${
                selectedContentType === 'contentCollection' ? 'collection' : selectedContentType
              }`
          }}
        </div>
        <div class="subtitle">
          {{ isSelectVideoSource ?
            t('bumper.create.selectVideoSource.description'):
            t('bumperSchedule.selectContent.description', {
              name:
                selectedContentType === 'contentCollection' ? 'collection' : selectedContentType,
            })
          }}
          <Button
            v-if="selectedContentType === 'video' && !isNotInProject"
            type="button"
            buttonStyle="link-primary"
            @click="onClickUploadVideo"
          >{{ t('common:uploadAnotherFile') }}</Button
          >
        </div>
        <PreLoaderSection v-if="loadContentsState === 'loading'" />
        <div
          v-if="loadContentsState === 'success' && !(contents.length === 0 && !searchTerm)"
          class="search-container"
        >
          <Input
            :onPressedEnter="onSearch"
            :modelValue="searchTerm"
            placeholder="Search by name"
            isSearchInput
          />
        </div>
        <template v-if="loadContentsState === 'success' && contents.length > 0">
          <div v-if="contents?.length" class="content-container">
            <button
              type="button"
              v-for="content in contents"
              :key="content.id"
              @click="() =>  {
                selectedContent = content;
                if (selectedContentType === 'project') {
                  emit('update:projectKey', selectedContent?.key);
                }
              }"
              :class="{ selected: selectedContent?.id === content.id }"
              class="btn content-item"
            >
              <div
                class="video-image"
                v-if="selectedContentType !== 'project' && checkIfContentIsNotTypeOfProject(content)"
              >
                <ImageView
                  :source="getVideoUploadCoverImage({ video: content, currentUser })"
                  imageSize="small"
                />
                <div
                  class="video-duration"
                  v-if="selectedContentType !== 'contentCollection' && !_.isNil(content) && 'duration' in content && !_.isNil(content.duration)"
                >
                  {{ getVideoDuration(content) }}
                </div>
              </div>
              <div class="content-title">
                <span v-if="'name' in content">
                  {{ content.name }}
                </span>
                <span v-else>
                  {{ content.title }}
                </span>
                <span class="video-count" v-if="'videoCount' in content">
                  ({{ content.videoCount }} videos)
                </span>
              </div>
            </button>
          </div>
          <!-- empty content -->
          <Pagination
            v-if="loadContentsState === 'success' && contentLastPage > 1"
            :last-page="contentLastPage"
          />
        </template>
        <template v-if="loadContentsState !== 'loading' && isNotInProject">
          <MessageBox
            :title="t('bumperSchedule.edit.noPermission.title')"
            :description="t('bumperSchedule.edit.noPermission.description')"
            iconClassName="fas fa-file-lock"
          />
        </template>
        <template
          v-else-if="loadContentsState === 'success' && contents.length === 0 && searchTerm"
        >
          <EmptySearch :keyword="searchTerm" :onClearSearch="onClearSearch" />
        </template>
        <template
          v-else-if="loadContentsState === 'success' && contents.length === 0 && !searchTerm"
        >
          <MessageBox
            :title="`No ${
              selectedContentType === 'contentCollection' ? 'collection' : selectedContentType
            }`"
            :description="errorMessages[`${selectedContentType}`]?.description"
            :iconClassName="errorMessages[`${selectedContentType}`]?.iconClassName"
            :buttonPrefixIconClassName="
              errorMessages[`${selectedContentType}`]?.buttonPrefixIconClassName
            "
            :buttonStyle="errorMessages[`${selectedContentType}`]?.buttonStyle"
            :buttonTitle="errorMessages[`${selectedContentType}`]?.buttonTitle"
            :onClickButton="() => onCreateNewContent(selectedContentType)"
          >
            <template v-slot:icon v-if="selectedContentType === 'contentCollection'">
              <img width="40" src="../../../../../assets/images/collection-icon.svg" alt="collection" />
            </template>
          </MessageBox>
        </template>
      </div>
    </template>
    <template v-slot:primary-btn-suffix v-if="computedPrimaryButtonTitle === t('common:button.next')">
      <i class="fas fa-chevron-right"></i>
    </template>
    <template v-slot:secondary-btn-prefix v-if="computedSecondaryButtonTitle === t('common:button.back')">
      <i class="fas fa-chevron-left"></i>
    </template>
    <template v-slot:footer v-if="computedSecondaryButtonTitle !== t('common:button.cancel')">
      <Button
        buttonStyle="text-secondary"
        @click="() => emit('modalClose')"
      >
        {{t('common:button.cancel')}}
      </Button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { Filter } from '@/modules/shared/types/axios.type';
import { SelectedContent, SelectedContentType } from '@/modules/bumperDisplay/types';
import { Project } from '@/modules/shared/types/project.type';
import { VideoUploadByKeyObject } from '@/modules/videoUpload/types/videoUpload.type';
import {
  computed, ref, watch, onBeforeMount, toRefs,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { loadProjectsAPI } from '@/modules/shared/services/project';
import { getCollections } from '@/modules/collectionList';
import { loadVideosAPI } from '@/modules/projectVideoList';
import {
  isVideoProcessing,
  getVideoDuration,
  getVideoUploadCoverImage,
} from '@/modules/videoDetail/utils/videoManagement';
import Button from '@/modules/shared/components/atoms/button/Button.vue';
import PreLoaderSection from '@/modules/shared/components/atoms/preLoaderSection/PreLoaderSection.vue';
import Modal from '@/modules/shared/components/organisms/modal/Modal.vue';
import Pagination from '@/modules/shared/components/molecules/pagination/Pagination.vue';
import SelectOption from '@/modules/shared/components/atoms/selectOption/SelectOption.vue';
import ImageView from '@/modules/shared/components/atoms/imageView/ImageView.vue';
import Input from '@/modules/shared/components/atoms/input/Input.vue';
import EmptySearch from '@/modules/shared/components/molecules/emptySearch/EmptySearch.vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useStore } from '../../../store/hooks';

interface SelectBumperContentModalProps {
  selectedContentType: SelectedContentType;
  projectKey?: string;
  submitButtonTitle?: string;
  secondaryButtonTitle?: string;
  isSelectVideoSource?: boolean;
  onSubmit: (value: SelectedContent) => Promise<void>;
}

const props = defineProps<SelectBumperContentModalProps>();
const {
  selectedContentType,
  projectKey,
  submitButtonTitle,
  secondaryButtonTitle,
  isSelectVideoSource,
  onSubmit,
} = toRefs(props);

const { t } = useI18n();
const route = useRoute();

const emit = defineEmits<{
  modalClose: [];
  'update:projectKey': [value: string];
  'update:currentStep': [];
}>();

const store = useStore();
const projects = ref<Array<Project>>([]);
const contents = ref<Array<VideoUploadByKeyObject | Project>>([]);
const selectedProject = ref<Project | null>(null);
const selectedContent = ref<VideoUploadByKeyObject | Project | null>(null);
const contentLastPage = ref<number>(1);
const loadContentsState = ref<string>('loading');
const searchTerm = ref<string>('');
const refreshVideoTimer = ref<number | null>(null);
const isNotInProject = ref<boolean>(false);

const teamName = computed(() => route.params.teamName);
const currentUser = computed(() => store.state.user.currentUser);
const computedPrimaryButtonTitle = computed(
  () => submitButtonTitle.value || t('common:button.next'),
);
const computedSecondaryButtonTitle = computed(
  () => secondaryButtonTitle.value || t('common:button.cancel'),
);
const selectedProjectKey = computed<string | undefined>(() => selectedProject.value?.key ?? projectKey.value);

const loadProjects = async (filter: Filter = {}) => {
  loadContentsState.value = 'loading';
  try {
    const { data } = await loadProjectsAPI(filter);
    if (!data) {
      loadContentsState.value = 'error';
      return;
    }

    if (selectedContentType.value === 'project') {
      contents.value = data.data;
      loadContentsState.value = 'success';
    } else {
      const projectList = data.data;
      projects.value = projectList.map((project: Project) => ({
        ...project,
        nameWithCount: `${project.name} (${project.videoCount} videos)`,
      }));

      if (projectKey.value) {
        selectedProject.value = projects.value.find((project) => project.key === projectKey.value) ?? null;
      } else if (!_.isEmpty(projects.value)) {
        // eslint-disable-next-line prefer-destructuring
        selectedProject.value = projects.value[0];
      }

      isNotInProject.value = Boolean(projectKey.value) && !selectedProject.value;
    }
  } catch (error) {
    loadContentsState.value = 'error';
  }
};
const loadVideos = async (filter: Filter = {}) => {
  if (!selectedProjectKey.value) {
    return;
  }

  loadContentsState.value = 'loading';
  try {
    const { data } = await loadVideosAPI({ ...filter, projectKey: selectedProjectKey.value, limit: '20' });
    contents.value = data?.data.map((item) => ({ ...item, $isDisabled: isVideoProcessing(item) }));
    contentLastPage.value = data?.lastPage;
    loadContentsState.value = 'success';
  } catch (error) {
    loadContentsState.value = 'error';
  }
};

const loadCollections = async (filter: Filter = {}) => {
  if (!selectedProjectKey.value) {
    return;
  }

  loadContentsState.value = 'loading';
  try {
    const { data } = await getCollections(selectedProjectKey.value, { ...filter, limit: '20' });
    contents.value = data?.data;
    contentLastPage.value = data?.lastPage;
    loadContentsState.value = 'success';
  } catch (error) {
    loadContentsState.value = 'error';
  }
};

const loadContents = async (filter: Filter = {}) => {
  switch (selectedContentType.value) {
    case 'video':
      await loadVideos(filter);
      break;
    case 'contentCollection':
      await loadCollections(filter);
      break;
    default:
  }
};

const onClickUploadVideo = () => {
  if (!selectedProject.value) {
    return;
  }

  refreshVideoTimer.value = setInterval(() => {
    loadVideos();
  }, 15000);

  window.open(`/${teamName.value}/projects/${selectedProject.value?.key}/upload`, '_blank');
};

const onSearch = (event: Event) => {
  if (event.target && event.target instanceof HTMLInputElement) {
    searchTerm.value = event.target.value;
  }
  // always keep tab value for parent SetBumperScheduleModal
  // router.push({ query: { tab: route.query.tab, q: event.target.value } });
};

const onClearSearch = () => {
  searchTerm.value = '';

  // always keep tab value for parent SetBumperScheduleModal
  // router.push({ query: { tab: route.query.tab } });
};

type ErrorMessages = Record<SelectedContentType, {[key: string]: string}>;

const errorMessages = computed<ErrorMessages>(() => ({
  project: {
    name: 'project',
    iconClassName: 'fas fa-briefcase',
    description:
      'Either your team has no project or you don’t have permission to view it. Ask your team to update permissions or create a new project for your contents.',
    buttonStyle: 'text-primary',
    buttonTitle: 'Create new project',
    buttonPrefixIconClassName: 'fas fa-plus',
  },
  contentCollection: {
    name: 'collection',
    description: `Project '${selectedProject.value?.name}' has no collection yet. Start creating a collection to group your contents.`,
    buttonStyle: 'text-primary',
    buttonTitle: 'Create new collection',
    buttonPrefixIconClassName: 'fas fa-plus',
  },
  video: {
    name: 'video',
    iconClassName: 'fas fa-play-circle',
    description: `Project ‘${selectedProject.value?.name}’ has no video yet. Start uploading your contents.`,
    buttonStyle: 'primary',
    buttonTitle: 'Upload',
    buttonPrefixIconClassName: 'fas fa-cloud-upload-alt',
  },
}));

const onCreateNewContent = (contentType: string) => {
  if (contentType === 'project') {
    window.open(`/${teamName.value}/projects/create`, '_blank');
  } else if (contentType === 'contentCollection') {
    window.open(`/${teamName.value}/projects/${selectedProjectKey.value}/collections`, '_blank');
  } else if (contentType === 'video') {
    window.open(`/${teamName.value}/projects/${selectedProjectKey.value}/upload`, '_blank');
  }
};

const onChangeProject = (value: Project) => {
  onClearSearch();
  isNotInProject.value = false;
  selectedProject.value = value;
};

const onClickSubmit = () => {
  onSubmit.value({
    type: selectedContentType.value,
    content: { content: selectedContent.value },
    projectKey: selectedProjectKey.value ?? '',
  });
};

/**
 * This function is used for a quick type checking for "content(s)" and "selectedContent" variables.
 *
 * This function should not be moved to the type guard file, because it really quick checks
 * if the [obj] is not a type of Project and null. And may not enough to say that this is a VideoUploadByKeyObject type guard.
 */
function checkIfContentIsNotTypeOfProject(obj: unknown): obj is VideoUploadByKeyObject {
  return typeof obj === 'object' && obj !== null
    && 'duration' in obj
    && 'playable' in obj
    && 'playbackUrls' in obj
    && 'vod' in obj;
}

onBeforeMount(async () => {
  // load project list
  await loadProjects();
});

watch(searchTerm, () => {
  if (selectedContentType.value === 'project') {
    loadProjects({ q: searchTerm.value });
  } else {
    loadContents({ q: searchTerm.value });
  }
});

watch(
  () => route.query?.page,
  () => {
    if (selectedContentType.value === 'project') {
      loadProjects({ q: searchTerm.value, page: route.query?.page });
    } else {
      loadContents({ q: searchTerm.value, page: route.query?.page });
    }
  },
);

watch(selectedProjectKey, () => loadContents(), { immediate: true });
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px;
}

.select-project {
  display: flex;
  width: 100%;
  height: max-content;
  gap: $spacing-8;
  justify-content: flex-end;
  align-items: center;

  .select-title {
    text-transform: capitalize;
  }

  .select-wrapper {
    width: 320px;
  }
}

.title {
  font-size: $font-level-6;
  font-weight: $font-weight-bold;
  color: $grey-800;
}

.subtitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.search-container {
  margin: $spacing-8 0px;

  :deep(.component-container) {
    &.text {
      max-width: none;
    }
  }
}

.content-container {
  margin-bottom: $spacing-24;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  height: 350px;

  .content-item {
    display: flex;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid $grey-200;
    border-radius: 5px;
    padding: $spacing-8 $spacing-base;
    gap: $spacing-8;
    align-items: center;
    cursor: pointer;

    &.selected {
      border-color: $ci-primary;
      background-color: #e9f2fb;
    }

    &:active,
    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: $grey-200;
    }

    .video-image {
      cursor: pointer;
      position: relative;
      width: 65px;

      :deep(img) {
        position: relative;
      }

      .video-duration {
        position: absolute;
        bottom: 0;
        right: 0;
        color: $grey-200;
        font-size: $font-level-8;
        background-color: rgba(0, 0, 0, 0.75);
        padding: 2px $spacing-4;
      }
    }

    .content-title {
      text-align: left;
      width: 60%;
      color: $grey-800;
      font-weight: $font-weight-bold;
      word-break: break-all;

      .video-count {
        color: $grey-600;
        font-weight: $font-weight-base;
      }
    }
  }
}

:deep(.message-box-container .button-container button) {
  font-size: $font-level-7;
  font-weight: $font-weight-base;
}
</style>
