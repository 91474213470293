<!-- eslint-disable -->
<template>
  <div class="container">
    <div class="date-input-wrapper">
      <Datepicker
        :enableTimePicker="false"
        :disabled="disabled"
        placeholder="Filter by date..."
        autoApply
        v-model="localValue"
        :minDate="minDate"
        :maxDate="maxDate"
        ref="datePickerRef"
        @update:modelValue="onDateChange"
        :format="displayFormat"
        class="date-input"
        weekStart="0"
        :monthPicker="currentTab === 'month'"
        :yearPicker="currentTab === 'year'"
        :range="currentTab === 'dateRange'"
        :monthChangeOnArrows="false"
        @closed="() => {
          if (checkIfInputError()) {
            emit('update:modelValue', null);
          }
        }"
      >
        <template #month-year="{ month, year, months, handleMonthYearChange }">
          <div class="advanced-date-picker-header-container">
            <div class="header">
              <div class="target-date-container">
                <label for="target-date" class="target-date-input-label">Target Date</label>
                <input
                  id="target-date"
                  :class="['target-date-input', { error: checkIfInputError() }]"
                  placeholder="Try: 1/2025"
                  type="text"
                  v-model="inputValue"
                  @keydown="onKeyDown"
                  @input.prevent="onInputValue"
                  @focus="() => {
                    checkIfInputError();
                    isInputting = true;
                  }"
                  @blur="() => {
                    isInputting = false;
                    checkIfInputError();
                  }"
                />
                <span v-if="checkIfInputError()" class="input-invalid-feedback">
                  {{isSelectedDateOutOfRange ? t('validation:advDatePicker.outOfRange') : t('validation:advDatePicker.wrongFormat')}}
                </span>
              </div>
              <div class="tab-buttons-container">
                <button
                  v-for="tab in tabs"
                  type="button"
                  :class="['btn', 'tab-button', { active: currentTab === tab.key }]"
                  :key="tab.key"
                  @click="() => onClickTab(tab.key)"
                >
                  {{tab.displayName}}
                </button>
              </div>
            </div>
            <div v-if="currentTab === 'dateRange'" class="day-view-current-month-container fade-in">
              <p class="month-text">{{ getDisplayMonthYear(month, year) }}</p>
              <div class="change-month-buttons-container">
                <button
                  type="button"
                  class="btn change-button"
                  @click="() => handleMonthYearChange?.(false, true)"
                >
                  <i class="far fa-chevron-left" />
                </button>
                <button
                  type="button"
                  class="change-button"
                  @click="() => handleMonthYearChange?.(true, true)"
                >
                  <i class="far fa-chevron-right" />
                </button>
              </div>
            </div>
            <div
              v-if="currentTab === 'month'"
              ref="monthViewContainer"
              class="month-view-container fade-in"
            >
              <div
                v-for="year in years"
                class="year-group"
                :key="year"
              >
                <p class="year-text">{{ year }}</p>
                <div
                  v-for="(monthGroup, index) in months"
                  class="month-group"
                  :key="`month-group-${index}`"
                >
                  <button
                    v-for="monthItem in monthGroup"
                    :key="monthItem.value"
                    type="button"
                    :disabled="checkIfMonthYearOutOfRange(monthItem.value, year)"
                    :class="['btn', 'btn-month-text', {
                      active: localValue.month === monthItem.value && localValue.year === year,
                    }]"
                    @click="() => {
                      if (checkIfMonthYearOutOfRange(monthItem.value, year)) {
                        return;
                      }

                      emit('update:modelValue', {
                        month: monthItem.value,
                        year,
                      });
                    }"
                  >
                    {{ monthItem.text }}
                  </button>
                </div>
              </div>
            </div>
            <div
              v-if="currentTab === 'year'"
              ref="yearViewContainer"
              class="year-view-container fade-in"
            >
              <button
                v-for="year in years"
                :class="['btn', 'btn-year-text', { active: typeof localValue === 'number' && localValue === year }]"
                @click="() => emit('update:modelValue', year)"
                class="btn btn-year-text"
                :key="year"
              >
                {{ year }}
              </button>
            </div>
          </div>
        </template>
        <template #input-icon>
          <i class="fas fa-calendar-days date-picker-icon" />
        </template>
      </Datepicker>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import Datepicker, { type VueDatePickerProps } from '@vuepic/vue-datepicker';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import type { AdvancedDatePickerValue, AdvancedDatePickerDateType } from '@/modules/shared/types/input.type';
import '@vuepic/vue-datepicker/dist/main.css';

interface AdvancedDatePickerProps {
  disabled?: boolean;
  modelValue: AdvancedDatePickerValue;
  minDate?: Date;
  maxDate?: Date;
  dateType?: 'dateRange' | 'month' | 'year';
}

interface Tab {
  displayName: string;
  key: AdvancedDatePickerDateType;
}

const DEFAULT_MIN_YEAR: Readonly<number> = 2015;

const props = withDefaults(defineProps<AdvancedDatePickerProps>(), {
  dateType: 'dateRange',
});

const {
  disabled,
  modelValue,
  minDate,
  maxDate,
  dateType,
} = toRefs(props);

const { t } = useI18n();

const emit = defineEmits<{
  'update:modelValue': [value: AdvancedDatePickerValue];
}>();

const formatMap: Record<AdvancedDatePickerDateType, string> = {
  dateRange: 'dd/MM/yyyy',
  month: 'MM/yyyy',
  year: 'yyyy',
};

const currentTab = ref<AdvancedDatePickerDateType>(dateType.value);
const displayFormat = computed(() => formatMap[currentTab.value]);

const localValue = ref<AdvancedDatePickerValue>(modelValue.value);
const inputValue = ref<string>('');
const datePickerRef = ref<VueDatePickerProps | null>(null);

const now = ref<Date>(new Date());
const years = ref<number[]>([]);
const monthViewContainer = ref<HTMLDivElement | null>(null);
const yearViewContainer = ref<HTMLDivElement | null>(null);
const isSelectedDateOutOfRange = ref<boolean>(false);
const isInputting = ref<boolean>(false);

const tabs: Tab[] = [
  { displayName: 'Day', key: 'dateRange' },
  { displayName: 'Month', key: 'month' },
  { displayName: 'Year', key: 'year' },
];

function getDisplayMonthYear(month: number | undefined, year: number): string {
  if (typeof month === 'number') {
    return new Date(year, month, 1).toLocaleString('default', { month: 'long', year: 'numeric' });
  }

  return '';
}

function getLastSelectedDate(): Date | null {
  if (!localValue.value) {
    return now.value;
  }

  if (Array.isArray(localValue.value)) {
    return new Date(localValue.value[0]);
  }

  if (typeof localValue.value === 'object' && 'month' in localValue.value && 'year' in localValue.value) {
    return new Date(localValue.value.year, localValue.value.month, now.value.getDate());
  }

  return new Date(localValue.value, now.value.getMonth(), now.value.getDate());
}

function updateInputValueByTabKey(tabKey: AdvancedDatePickerDateType) {
  if (!localValue.value) {
    inputValue.value = '';
    return;
  }

  const dateFormat = 'dd/MM/yyyy';

  if (tabKey === 'dateRange' && Array.isArray(localValue.value)) {
    inputValue.value = `${format(localValue.value[0], dateFormat)}${localValue.value[1] ? ` - ${format(localValue.value[1], dateFormat)}` : ''}`;
  } else if (tabKey === 'month' && typeof localValue.value === 'object' && 'month' in localValue.value && 'year' in localValue.value) {
    const displayMonth = localValue.value.month + 1;
    inputValue.value = `${displayMonth < 9 ? `0${displayMonth}` : displayMonth}/${localValue.value.year}`;
  } else {
    inputValue.value = `${localValue.value}`;
  }
}

function checkIfMonthYearOutOfRange(month: number, year: number): boolean {
  const isExceedNow = now.value.getFullYear() === year && month > now.value.getMonth();
  let isBelowMinDate = false;
  let isExceedMaxDate = false;

  if (minDate.value) {
    const minMonth = minDate.value.getMonth();
    const minYear = minDate.value.getFullYear();

    isBelowMinDate = year < minYear || (year === minYear && month < minMonth);
  }

  if (maxDate.value) {
    const maxMonth = maxDate.value.getMonth();
    const maxYear = maxDate.value.getFullYear();

    isExceedMaxDate = year > maxYear || (year === maxYear && month > maxMonth);
  }

  return isExceedNow || isBelowMinDate || isExceedMaxDate;
}

function checkIfFullDateOutOfRange(date: Date): boolean {
  const baseDateWithoutTime = date.setHours(0, 0, 0, 0);
  const minDateWithoutTime = minDate.value ? minDate.value.setHours(0, 0, 0, 0) : baseDateWithoutTime;
  const maxDateWithoutTime = maxDate.value ? maxDate.value.setHours(0, 0, 0, 0) : baseDateWithoutTime;

  /** แปล: minDate มากกว่าวันที่เลือก || วันที่เลือกมากกว่า maxDate */
  if ((isAfter(minDateWithoutTime, baseDateWithoutTime)) || (isAfter(baseDateWithoutTime, maxDateWithoutTime))) {
    return true;
  }

  return false;
}

function onClickTab(tabKey: AdvancedDatePickerDateType): void {
  if (currentTab.value === tabKey) {
    return;
  }

  currentTab.value = tabKey;
  isSelectedDateOutOfRange.value = false;

  const lastSelectedDate = getLastSelectedDate();

  const baseDate = lastSelectedDate ?? now.value;

  if (tabKey === 'dateRange') {
    localValue.value = [baseDate, baseDate];
    isSelectedDateOutOfRange.value = checkIfFullDateOutOfRange(baseDate);
  } else if (tabKey === 'month') {
    localValue.value = {
      month: baseDate.getMonth(),
      year: baseDate.getFullYear(),
    };

    isSelectedDateOutOfRange.value = checkIfMonthYearOutOfRange(baseDate.getMonth(), baseDate.getFullYear());

    setTimeout(() => {
      if (monthViewContainer.value) {
        monthViewContainer.value!.scrollTo({ left: 0, top: 99999 });
      }
    }, 50);
  } else {
    localValue.value = baseDate.getFullYear();

    setTimeout(() => {
      if (yearViewContainer.value) {
        yearViewContainer.value!.scrollTo({ left: 0, top: 99999 });
      }
    }, 50);
  }

  updateInputValueByTabKey(tabKey);
}

function onDateChange(value: AdvancedDatePickerValue): void {
  if (disabled.value) {
    return;
  }

  if (!value) {
    localValue.value = null;
    inputValue.value = '';
    emit('update:modelValue', value);
    return;
  }

  emit('update:modelValue', value);
}

const dayRegex = '([0-2]\\d|3[0-1]|[1-9])';
const monthRegex = '(0?[1-9]|[1][0-2])';
const yearRegex = '(2\\d{2}[1-9]|2\\d[1-9]0|[3-9]\\d{3}|\\d{2,}\\d{3})';

const yearPattern = `^${yearRegex}$`;
const monthYearPattern = `^${monthRegex}/${yearRegex}$`;
const dmyPattern = `^${dayRegex}/${monthRegex}/${yearRegex} - ${dayRegex}/${monthRegex}/${yearRegex}$`;

const useDebounce = _.debounce(() => {
  isInputting.value = false;
}, 1000);

function onInputValue(event: KeyboardEvent): void {
  isInputting.value = true;
  isSelectedDateOutOfRange.value = false;
  useDebounce();

  const { value } = (event.target as HTMLInputElement);

  if (new RegExp(yearPattern).test(value)) {
    const year = parseInt(value, 10);

    if ((maxDate.value && year > maxDate.value.getFullYear()) || (minDate.value && year < minDate.value.getFullYear())) {
      isSelectedDateOutOfRange.value = true;
      return;
    }

    localValue.value = year;
    currentTab.value = 'year';
    return;
  }

  const monthYearMatch = value.match(new RegExp(monthYearPattern));
  if (monthYearMatch) {
    const [, month, year] = monthYearMatch;
    const parsedMonth = parseInt(month, 10) - 1;
    const parsedYear = parseInt(year, 10);

    isSelectedDateOutOfRange.value = checkIfMonthYearOutOfRange(parsedMonth, parsedYear);

    if (isSelectedDateOutOfRange.value) {
      return;
    }

    localValue.value = { month: parsedMonth, year: parsedYear };
    currentTab.value = 'month';
    return;
  }

  const dayMonthYearRangeMatch = value.match(new RegExp(dmyPattern));

  if (dayMonthYearRangeMatch) {
    const [, day1, month1, year1, day2, month2, year2] = dayMonthYearRangeMatch;
    const [iDay1, iMonth1, iYear1, iDay2, iMonth2, iYear2] = [day1, month1, year1, day2, month2, year2].map((string) => parseInt(string, 10));

    const startDate = new Date(iYear1, iMonth1 - 1, iDay1);
    const endDate = new Date(iYear2, iMonth2 - 1, iDay2);

    isSelectedDateOutOfRange.value = checkIfFullDateOutOfRange(startDate) || checkIfFullDateOutOfRange(endDate);

    if (isSelectedDateOutOfRange.value) {
      return;
    }

    localValue.value = [startDate, endDate];
    currentTab.value = 'dateRange';
  }
}

function checkIfInputError(): boolean {
  if (!inputValue.value || isInputting.value) {
    return false;
  }

  return isSelectedDateOutOfRange.value || [yearPattern, monthYearPattern, dmyPattern].every((pattern) => !new RegExp(pattern).test(inputValue.value));
}

function getYearList(): number[] {
  const min = minDate.value ? minDate.value.getFullYear() : DEFAULT_MIN_YEAR;
  return Array.from({ length: now.value.getFullYear() - min + 1 }, (_unused, i) => now.value.getFullYear() - i).toReversed();
}

function onKeyDown(e: KeyboardEvent) {
  /**
   * This handler allows using keyboard's left/right arrows to change a cursor position.
   * This basic functionality has gone because of the VueDatePicker.
   * */
  const { currentTarget, code } = e;

  if (!currentTarget || !(currentTarget instanceof HTMLInputElement)) {
    return;
  }

  if (code === 'ArrowLeft') {
    e.stopPropagation();
    e.preventDefault();
    currentTarget.setSelectionRange(
      currentTarget.selectionStart,
      (currentTarget.selectionStart ?? 0) - 1,
    );
    return;
  }

  if (code === 'ArrowRight') {
    e.stopPropagation();
    currentTarget.setSelectionRange(
      currentTarget.selectionStart,
      (currentTarget.selectionStart ?? 0) + 1,
    );
    return;
  }

  if (code === 'Enter' || code === 'NumpadEnter') {
    isInputting.value = false;

    if (checkIfInputError() || isSelectedDateOutOfRange.value) {
      return;
    }

    emit('update:modelValue', localValue.value);
  }
}

onMounted(() => {
  years.value = getYearList();
});

watch(modelValue, (newValue) => {
  localValue.value = newValue;
});

watch(localValue, () => updateInputValueByTabKey(currentTab.value));
// eslint-disable-next-line no-return-assign
watch(dateType, (type) => currentTab.value = type, { immediate: true });
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.container {
  display: flex;
  margin: 0;
  padding: 0;

  .date-input-wrapper {
    width: 100%;
  }

  .time-input-wrapper {
    width: 130px;
  }
}

.btn {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  color: $grey-600;
  cursor: pointer;
  position: relative;
  transition: all 150ms;

  &:hover {
    color: $grey-800;
  }

  &.active {
    background-color: $ci-primary;
    border-color: $ci-primary-light;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

.advanced-date-picker-header-container {
  width: 100%;
  min-width: 269px;
}

.header {
  width: 100%;
  padding-top: $spacing-base;
  padding-right: $spacing-base;
  padding-bottom: $spacing-8;
  padding-left: $spacing-base;
  border-bottom: 1px solid $grey-300;

  .target-date-container {
    position: relative;
    margin-bottom: $spacing-8;

    .target-date-input-label {
      display: block;
      font-size: $font-level-7;
      color: $grey-700;
      margin-bottom: 2px;
    }

    .target-date-input {
      display: block;
      border: 1px solid $grey-300;
      border-radius: $border-radius-5;
      box-shadow: 0 0 4px transparent;
      color: $grey-800;
      outline: 0;
      padding: 4px 8px;
      width: 100%;

      &:focus {
        outline: 0;
        border-color: $ci-primary;

        &.error {
          border-color: $danger;
        }
      }

      &.error {
        border-color: $danger;
      }
    }

    .input-invalid-feedback {
      position: absolute;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
      backdrop-filter: blur(2px);
      color: $danger;
      right: 6px;
      bottom: 5px;
      font-size: 11px;
      padding-left: 4px;
      pointer-events: none;
    }
  }

  .tab-buttons-container {
    background-color: rgb(249, 249, 249);
    box-shadow: rgb(224, 224, 224) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.04) 0px 1px 1px 0px inset;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    padding: 0px;
    position: relative;
    width: 100%;

    .tab-button {
      display: inline-flex;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      gap: 6px;

      padding: 4px 12px;
      height: 24px;
      transition-property: background-color, color;
      transition-duration: 150ms;

      &:hover {
        color: $grey-800;
      }

      &.active {
        background-color: #FFF;
        border: 1px solid rgb(224, 224, 224);
        color: rgb(47, 48, 47);
        box-shadow: none;
        border-radius: 5px;
      }
    }
  }
}

.day-view-current-month-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $spacing-base;
  padding-right: $spacing-base;
  padding-top: $spacing-8;

  .month-text {
    font-size: $font-level-7;
    color: $grey-800;
    margin-bottom: 2px;
  }

  .change-month-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .change-button {
      background-color: transparent;
      border: none;
      box-shadow: none;
      border-radius: 5px;
      outline: none;
      color: $grey-600;
      cursor: pointer;
      font-size: 11px;
      transition: background-color 150ms;

      &:hover {
        background-color: $grey-200;
      }
    }
  }
}

.month-view-container {
  max-height: 200px;
  width: 100%;
  overflow-y: auto;

  .year-group {
    padding: $spacing-8;
    margin-bottom: $spacing-8;

    .year-text {
      font-size: $font-level-7;
      color: $grey-700;
    }
  }

  .month-group {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
    margin-top: $spacing-8;

    .btn-month-text {
      border: 1px solid $grey-300;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.year-view-container {
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  padding: $spacing-8;

  .btn-year-text {
    display: block;
    width: 100%;
    padding: $spacing-8;
  }
}

.date-picker-icon {
  color: $grey-600;
  padding: 6px 12px;
}

:deep(.dp__menu) {
  border: 0 !important;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}

:deep(.dp__menu_inner) {
  padding: 0;
}

:deep(.dp__calendar_header) {
  border-bottom: 1px solid $grey-100;
  margin-top: 0;
}

:deep(.dp--header-wrap), :deep(.dp--menu--inner-stretched) {
  padding-top: 0;
}

:deep(.dp__calendar) {
  padding: $spacing-base 6px;
  padding-top: 0;
}

:deep(.dp__input) {
  //background-color: #EBEDEF;
  border: 1px solid #e8e8e8;
  //
  //&::placeholder {
  //  color: $grey-600;
  //}
}

.fade-in {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 200ms;
  animation-delay: 20ms;
  animation-fill-mode: forwards;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
