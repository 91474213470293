import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "error-code"
}

import { toRefs } from 'vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useI18n } from 'vue-i18n';

interface ErrorMessageProps {
  statusCode?: string | number;
  iconClassName?: string;
  title?: string;
  description?: string;
  buttonTitle?: string;
  buttonPrefixIconClassName?: string;
  buttonUrl?: string;
  onClickButton?: () => void;
}
// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorMessage',
  props: {
    statusCode: {},
    iconClassName: {},
    title: {},
    description: {},
    buttonTitle: {},
    buttonPrefixIconClassName: {},
    buttonUrl: {},
    onClickButton: { type: Function }
  },
  setup(__props: any) {

const props = __props;
const { t } = useI18n();

const {
  statusCode,
  iconClassName,
  title,
  description,
  buttonTitle,
  buttonUrl,
  buttonPrefixIconClassName,
  onClickButton,
} = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MessageBox, {
    title: _unref(title),
    buttonTitle: _unref(buttonTitle),
    buttonUrl: _unref(buttonUrl),
    buttonPrefixIconClassName: _unref(buttonPrefixIconClassName) ?? 'fas fa-chevron-left',
    "button-style": "primary",
    iconClassName: _unref(iconClassName),
    onClickButton: _unref(onClickButton)
  }, {
    description: _withCtx(() => [
      _createElementVNode("div", { innerHTML: _unref(description) }, null, 8, _hoisted_1),
      (_unref(statusCode) === 500)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            _createTextVNode(_toDisplayString(_unref(t)('response:loadingFailed.refresh')) + " ", 1),
            _cache[0] || (_cache[0] = _createElementVNode("a", {
              href: "mailto:support@byteark.com",
              target: "_top"
            }, " support@byteark.com ", -1))
          ]))
        : _createCommentVNode("", true),
      (_unref(statusCode))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " (Code: " + _toDisplayString(_unref(statusCode) || 'Not found') + ") ", 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "buttonTitle", "buttonUrl", "buttonPrefixIconClassName", "iconClassName", "onClickButton"]))
}
}

})