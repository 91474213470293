<template>
  <SlideOut
    :title="t('common:filters')"
    titleIconClassName="fa fa-filter"
    :isShowModalFooter="false"
    @slideOutClose="onClose"
    dataTest="filter-slide-out"
  >
    <div v-if="useDateFilter">
      <p class="filter-title">{{t('glossary:date')}}</p>
      <AdvancedDatePicker
        v-model="filteredDate"
        :minDate="currentProject ? new Date(currentProject.createdAt) : undefined"
        :maxDate="now"
        :dateType="filteredDateType"
      />
    </div>
    <FormMultiSelect
      :title="t('common:tags')"
      :modelValue="selectedTags"
      :options="options"
      :searchable="true"
      :taggable="true"
      @onSearch="onSearchTag"
      @onChanged="onChangeTags"
    />
    <Button
      buttonStyle="text-primary"
      buttonClasses="clear-search-button"
      @click="clearSearch">
      <template v-slot:content>
        {{ t('common:tag.resultEmpty.buttonTitle') }}
      </template>
    </Button>
  </SlideOut>
</template>

<script lang="ts" setup>
import type { AdvancedDatePickerDateType } from '@/modules/shared/types/input.type';
import {
  computed, onMounted, ref, toRefs, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { getQueryAsString, type AdvancedDatePickerValue, type VideoTag } from '@/modules/shared';
import { loadVideoTags } from '@/modules/videoDetail/services';
import {
  AdvancedDatePicker, Button, SlideOut, FormMultiSelect,
} from '@/modules/shared';
import { useStore } from '@/modules/projectVideoList/store/hooks';

interface Props {
  useDateFilter?: boolean;
  onClose: () => void;
}

const props = defineProps<Props>();
const { useDateFilter, onClose } = toRefs(props);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();

const now = new Date();

const options = ref<VideoTag[]>([]);
const selectedTags = ref<VideoTag[]>([]);
const filteredDateType = ref<AdvancedDatePickerDateType>('dateRange');
const filteredDate = ref<AdvancedDatePickerValue>(null);

const projectKey = computed(() => getQueryAsString(route.params, 'projectKey'));
const currentProject = computed(() => store.state.project.currentProject);

async function loadTags(q?: string) {
  if (!projectKey.value) {
    return;
  }

  try {
    const response = await loadVideoTags({ projectKey: projectKey.value, q });
    options.value = response.data?.data;
  } catch (error) {
    console.error(error);
  }
}

function onSearchTag(query: { q: string }) {
  loadTags(query.q);
}

function pushQueryParam() {
  const tagIds = selectedTags.value?.map((tag) => tag.id);
  router.push({ query: { tagIds: tagIds.join(',') } });
}

function onChangeTags(tags: VideoTag[]) {
  selectedTags.value = tags;
  pushQueryParam();
}

function getSelectedTags() {
  let { tagIds } = route.query;

  if (typeof tagIds === 'string' && tagIds.length > 0) {
    tagIds = tagIds.split(',');

    const selected: VideoTag[] = [];

    // preserve user selected order
    tagIds.forEach((id) => {
      const tag = options.value?.find((item) => item.id === id);
      if (tag) {
        selected?.push(tag);
      }
    });

    selectedTags.value = selected;
  }
}

function clearSearch() {
  router.push({ query: {} });
  selectedTags.value = [];
  onClose.value();
}

watch(filteredDate, (date) => {
  const prevQuery = {
    ...route.query,
    next: undefined,
    previous: undefined,
  };

  if (!date) {
    router.push({
      query: {
        ...prevQuery,
        dateFilterType: undefined,
        startDate: undefined,
        endDate: undefined,
        month: undefined,
        year: undefined,
      },
    });
    return;
  }

  if (typeof date === 'number') {
    router.push({
      query: {
        ...prevQuery,
        dateFilterType: 'year',
        startDate: undefined,
        endDate: undefined,
        month: undefined,
        year: date,
      },
    });
    return;
  }

  if (typeof date === 'object' && 'month' in date && 'year' in date) {
    router.push({
      query: {
        ...prevQuery,
        dateFilterType: 'month',
        startDate: undefined,
        endDate: undefined,
        month: date.month,
        year: date.year,
      },
    });
    return;
  }

  router.push({
    query: {
      ...prevQuery,
      dateFilterType: 'dateRange',
      startDate: date[0].toISOString().substring(0, 10),
      endDate: date[1].toISOString().substring(0, 10),
      month: undefined,
      year: undefined,
    },
  });
});

function setDefaultFilteredDateFromQuery(): void {
  const dateObject = {
    dateFilterType: route.query.dateFilterType,
    startDate: route.query.startDate,
    endDate: route.query.endDate,
    month: route.query.month,
    year: route.query.year,
  };

  const {
    dateFilterType, startDate, endDate, month, year,
  } = dateObject;

  if (dateFilterType === 'year' && typeof year === 'string') {
    filteredDateType.value = dateFilterType;
    filteredDate.value = parseInt(year, 10);
    return;
  }

  if (dateFilterType === 'month' && typeof year === 'string' && typeof month === 'string') {
    filteredDateType.value = dateFilterType;
    filteredDate.value = {
      month: parseInt(month, 10),
      year: parseInt(year, 10),
    };
    return;
  }

  if (dateFilterType === 'dateRange' && typeof startDate === 'string' && typeof endDate === 'string') {
    filteredDateType.value = dateFilterType;
    filteredDate.value = [new Date(startDate), new Date(endDate)];
  }
}

onMounted(async () => {
  await loadTags();
  getSelectedTags();
  setDefaultFilteredDateFromQuery();
});
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';

:deep(.clear-search-button) {
  margin-left: auto;
  margin-top: $spacing-base * 1.5;
}

:deep(.multiselect__tags) {
  border-radius: 5px;
}

.filter-title {
  color: #2E3946;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: $spacing-8 !important;
}
</style>
