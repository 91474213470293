<template>
  <div class="app-chrome">
    <router-view />
    <UploadSummaryModal v-if="!isUploadSummaryMinimized" />
    <transition name="slide">
      <VideoUploadProgressPopup v-if="hasUploadQueue && isFirstTimeUploadSummaryMinimized" />
    </transition>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { computed, watch } from 'vue';
import { useStore } from '@/store';
import { VideoUploadProgressPopup, UploadSummaryModal, type VideoUploadState } from '@/modules/videoUpload';

const store = useStore<{videoUpload: VideoUploadState}>();

const hasUploadQueue = computed(() => !_.isEmpty(_.get(store, 'state.videoUpload.videoUploadKeys', [])));
const videoKeysGroupByState = computed(() => store.state.videoUpload.videoKeysGroupByState);
const isUploadSummaryMinimized = computed(() => store.state.videoUpload.isUploadSummaryMinimized);
const isFirstTimeUploadSummaryMinimized = computed(() => store.state.videoUpload.isFirstTimeUploadSummaryMinimized);
const uploadingVideoKeys = computed(() => _.get(videoKeysGroupByState.value, 'uploading', []));
const isUploadingVideos = computed(() => !_.isEmpty(uploadingVideoKeys.value));

function handleBeforeCloseBrowser(event: Event) {
  if (isUploadingVideos.value) {
    event.preventDefault();
    // Older browsers supported custom message
    // eslint-disable-next-line no-param-reassign
    event.returnValue = true;
    return 'onbeforeunload';
  }

  return undefined;
}

watch(isUploadingVideos, (isUploading) => {
  window.removeEventListener('beforeunload', handleBeforeCloseBrowser);
  window.removeEventListener('unload', handleBeforeCloseBrowser);

  if (isUploading) {
    window.addEventListener('beforeunload', handleBeforeCloseBrowser);
    window.addEventListener('unload', handleBeforeCloseBrowser);
  }
}, { immediate: true });
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
</style>
