<template>
  <Modal
    :size="size"
    :title="title || t('common:button.confirmRemove')"
    :primaryButtonText="buttonTitle || t('common:button.confirm')"
    :primaryButtonStyle="buttonStyle"
    :onClickPrimaryButton="onConfirm"
    :secondaryButtonText="enableCancelButton && t('common:button.cancel')"
    secondaryButtonStyle="text-secondary"
    :isSaving="isSaving"
    :zIndex="zIndex"
    @modalClose="onClose"
  >
    <template v-slot:body>
      <slot name="body" />
    </template>
    <template v-slot:footer>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { Modal, type ModalProps } from '@/modules/shared';

interface ConfirmModalProps {
  title?: ModalProps['title'];
  size?: ModalProps['size'];
  onClose: ModalProps['onClose'];
  buttonTitle?: ModalProps['primaryButtonText'];
  onConfirm: ModalProps['onClickPrimaryButton'];
  enableCancelButton?: boolean;
  buttonStyle?: ModalProps['buttonStyle'];
  isSaving?: ModalProps['isSaving'];
  zIndex?: ModalProps['zIndex'];
}

const props = defineProps<ConfirmModalProps>();
const {
  title,
  size,
  onClose,
  buttonTitle,
  onConfirm,
  enableCancelButton,
  buttonStyle,
  isSaving,
  zIndex,
} = toRefs(props);

const { t } = useI18n();
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

:slotted(p) {
  white-space: pre-line;
}
</style>
