import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { toRefs } from 'vue';
import { useI18n } from 'vue-i18n';
import { Modal, type ModalProps } from '@/modules/shared';

interface ConfirmModalProps {
  title?: ModalProps['title'];
  size?: ModalProps['size'];
  onClose: ModalProps['onClose'];
  buttonTitle?: ModalProps['primaryButtonText'];
  onConfirm: ModalProps['onClickPrimaryButton'];
  enableCancelButton?: boolean;
  buttonStyle?: ModalProps['buttonStyle'];
  isSaving?: ModalProps['isSaving'];
  zIndex?: ModalProps['zIndex'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ConfirmModal',
  props: {
    title: {},
    size: {},
    onClose: {},
    buttonTitle: {},
    onConfirm: { type: Function },
    enableCancelButton: { type: Boolean },
    buttonStyle: {},
    isSaving: { type: Boolean },
    zIndex: {}
  },
  setup(__props: any) {

const props = __props;
const {
  title,
  size,
  onClose,
  buttonTitle,
  onConfirm,
  enableCancelButton,
  buttonStyle,
  isSaving,
  zIndex,
} = toRefs(props);

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Modal), {
    size: _unref(size),
    title: _unref(title) || _unref(t)('common:button.confirmRemove'),
    primaryButtonText: _unref(buttonTitle) || _unref(t)('common:button.confirm'),
    primaryButtonStyle: _unref(buttonStyle),
    onClickPrimaryButton: _unref(onConfirm),
    secondaryButtonText: _unref(enableCancelButton) && _unref(t)('common:button.cancel'),
    secondaryButtonStyle: "text-secondary",
    isSaving: _unref(isSaving),
    zIndex: _unref(zIndex),
    onModalClose: _unref(onClose)
  }, {
    body: _withCtx(() => [
      _renderSlot(_ctx.$slots, "body")
    ]),
    footer: _withCtx(() => _cache[0] || (_cache[0] = [])),
    _: 3
  }, 8, ["size", "title", "primaryButtonText", "primaryButtonStyle", "onClickPrimaryButton", "secondaryButtonText", "isSaving", "zIndex", "onModalClose"]))
}
}

})