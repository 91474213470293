import type { GlobalState } from '@/store/type';
import _ from 'lodash';

const state: GlobalState = {
  isPageLoading: true,
  lastNavigatedProjectListPage: 1,
  areProjectVideosMoved: false,
  areProjectVideosDeleted: false,
  isProjectDeleted: false,
  isProjectRestored: false,
  isVideoRestored: false,
  isShowWelcomeModal: false,
  wasWelcomeModalShown: false,
};

const defaultState = _.cloneDeep(state);

const getters = {};

const mutations = {
  SET_PAGE_LOADING(state: GlobalState, boolean: boolean) {
    state.isPageLoading = boolean;
  },
  resetGlobalState(state: GlobalState) {
    Object.assign(state, defaultState);
  },
  setLastNavigatedProjectListPage(state: GlobalState, value: number) {
    state.lastNavigatedProjectListPage = value;
  },
  setAreProjectVideosMoved(state: GlobalState, value: boolean) {
    state.areProjectVideosMoved = value;
  },
  setAreProjectVideosDeleted(state: GlobalState, value: boolean) {
    state.areProjectVideosDeleted = value;
  },
  setIsProjectDeleted(state: GlobalState, value: boolean) {
    state.isProjectDeleted = value;
  },
  setIsProjectRestored(state: GlobalState, value: boolean) {
    state.isProjectRestored = value;
  },
  setIsVideoRestored(state: GlobalState, value: boolean) {
    state.isVideoRestored = value;
  },
  setIsShowWelcomeModal(state: GlobalState, value: boolean) {
    state.isShowWelcomeModal = value;
  },
  setWasWelcomeModalShown(state: GlobalState, value: boolean) {
    state.wasWelcomeModalShown = value;
  },
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
