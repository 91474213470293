<template>
  <MessageBox
    :title="title"
    :buttonTitle="buttonTitle"
    :buttonUrl="buttonUrl"
    :buttonPrefixIconClassName="buttonPrefixIconClassName ?? 'fas fa-chevron-left'"
    button-style="primary"
    :iconClassName="iconClassName"
    :onClickButton="onClickButton">
    <template v-slot:description>
      <div v-html="description" />
      <p v-if="statusCode === 500">
        {{t('response:loadingFailed.refresh')}}
        <a href="mailto:support@byteark.com" target="_top">
          support@byteark.com
        </a>
      </p>
      <div v-if="statusCode" class="error-code"> (Code: {{ statusCode || 'Not found' }}) </div>
    </template>
  </MessageBox>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';
import MessageBox from '@/modules/shared/components/molecules/messageBox/MessageBox.vue';
import { useI18n } from 'vue-i18n';

interface ErrorMessageProps {
  statusCode?: string | number;
  iconClassName?: string;
  title?: string;
  description?: string;
  buttonTitle?: string;
  buttonPrefixIconClassName?: string;
  buttonUrl?: string;
  onClickButton?: () => void;
}
// eslint-disable-next-line no-undef
const props = defineProps<ErrorMessageProps>();
const { t } = useI18n();

const {
  statusCode,
  iconClassName,
  title,
  description,
  buttonTitle,
  buttonUrl,
  buttonPrefixIconClassName,
  onClickButton,
} = toRefs(props);
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/global-variables.scss';
@import '~@/assets/scss/breakpoint.scss';

/* .error-code {
  margin-top: $spacing-base;
} */
</style>
