<template>
  <div class="container">
    <div class="date-input-wrapper">
      <Datepicker
        :enableTimePicker="false"
        :disabled="disabled"
        placeholder="dd/mm/yyyy"
        autoApply
        v-model="date"
        :minDate="minDate"
        :maxDate="maxDate"
        ref="dateInput"
        @update:modelValue="onDateChange"
        format="dd/MM/yyyy"
        class="date-input"
        weekStart="0"
      >
        <template #input-icon>
          <i class="fas fa-calendar-days date-picker-icon" />
        </template>
      </Datepicker>
    </div>
    <div v-if="!disableTimeSelector" class="time-input-wrapper">
      <Datepicker
        timePicker
        placeholder="HH:mm"
        v-model="time"
        ref="timeInput"
        @update:modelValue="onTimeChange"
        :disabled="disabled"
      >
        <template #input-icon>
          <i class="fas fa-clock date-picker-icon" />
        </template>
      </Datepicker>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Datepicker, { type PublicMethods } from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref, toRefs } from 'vue';

interface DateTimeSelectorProps {
  disabled?: boolean;
  modelValue: Date | string;
  minDate?: Date | string;
  maxDate?: Date | string;
  disableTimeSelector?: boolean;
}

const props = defineProps<DateTimeSelectorProps>();
const {
  disabled,
  modelValue,
  minDate,
  maxDate,
  disableTimeSelector,
} = toRefs(props);

const emit = defineEmits<{
  'update:modelValue': [value: string | null];
}>();

const timeInput = ref<PublicMethods | null>(null);
const date = ref<Date | null>(modelValue.value ? new Date(modelValue.value) : null);
const time = ref<{ hours: number; minutes: number } | null>(modelValue.value ? {
  hours: new Date(modelValue.value).getHours(),
  minutes: new Date(modelValue.value).getMinutes(),
} : null);

function onDateChange(value: Date | null) {
  if (disabled.value) {
    return;
  }

  if (!value) {
    date.value = null;
    emit('update:modelValue', value);
    return;
  }

  emit('update:modelValue', value.toISOString());

  if (value && timeInput.value && !disableTimeSelector.value) {
    timeInput.value.openMenu();
  }
}

function onTimeChange(value: { hours: number; minutes: number; seconds: number } | null) {
  if (disabled.value || disableTimeSelector.value) {
    return;
  }

  if (value) {
    if (!date.value) {
      date.value = new Date();
    }
    const updatedDate = date.value;
    updatedDate.setHours(value.hours, value.minutes, value.seconds, 0);
    emit('update:modelValue', updatedDate.toISOString());
  } else {
    date.value = null;
    emit('update:modelValue', value);
  }
}

// function onTimeClose() {
//   if (date.value && timeInput.value) {
//     timeInput.value.selectDate();
//   }
// }
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/global-variables.scss";

.container {
  display: flex;
  margin: 0;
  padding: 0;

  .date-input-wrapper {
    width: 180px;
    margin-right: $spacing-12;
  }

  .time-input-wrapper {
    width: 130px;
  }
}

.date-picker-icon {
  color: $grey-500;
  padding: 6px 12px;
}

::v-global(.dp__input_focus), ::v-global(.dp__input:hover){
  border: 1px solid #2e84db !important;
  box-shadow: 0 0 0 2px #E9F2FB !important;
}
::v-global(.dp__active_date) {
  color: #FFF !important;
}
::v-global(.dp__month_year_select) {
  font-size: $font-level-7;
}
::v-global(.dp__menu) {
  border: 0 !important;
  border-radius: $border-radius-5;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  /* padding: $spacing-12; */
}
::v-global(.dp__month_year_row) {
  color: $grey-600 !important;
  margin-bottom: $spacing-8;
  padding: $spacing-base $spacing-12 0 $spacing-12;
  font-size: $font-level-7;
}
::v-global(.dp__inner_nav) {
  color: $grey-600 !important;
  font-size: $font-level-7;
}
::v-global(.dp__calendar_header_separator) {
  display: none;
}
::v-global(.dp__calendar_header) {
  margin: $spacing-8 0;
}
::v-global(.dp__calendar_header_item) {
  color: $grey-600;
  font-weight: $font-weight-bold;
  padding: $spacing-8 !important;
  font-size: $font-level-7;
}
::v-global(.dp__calendar_wrap) {
  padding: 0 $spacing-12 $spacing-8 $spacing-12;
}
::v-global(.dp__cell_offset) {
  color: $grey-300 !important;
  font-size: $font-level-7;
}
::v-global(.dp__calendar_item) {
  color: $grey-800 !important;
  font-size: $font-level-7;
}
::v-global(.dp__cell_inner) {
  padding: $spacing-8 !important;
  border-radius: $border-radius-5 !important;
}
::v-global(.dp__today) {
  background: #E9F2FB !important;
  border: 0 !important;
  color: $ci-primary !important;
}
::v-global(.dp__calendar_row) {
  margin: 0 !important;
}

// Time Picker
::v-global(.dp__time_display), ::v-global(.dp__time_col) {
  color: $grey-800 !important;
}
::v-global(.dp__inc_dec_button) {
  color: $grey-600 !important;
}
::v-global(.dp__action_row) {
  padding: $spacing-12 !important;
}
::v-global(.dp__action) {
  padding: 2px 4px !important;
}
::v-global(.dp__selection_preview) {
  font-size: $font-level-7 !important;
  color: $grey-800 !important;
}
::v-global(.dp__select) {
  color: $ci-primary !important;
  font-size: $font-level-7 !important;
  font-weight: $font-weight-bold !important;
}
::v-global(.dp__cancel) {
  color: $grey-600 !important;
  font-size: $font-level-7 !important;
  font-weight: $font-weight-base !important;
}
</style>
